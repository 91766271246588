import { useEffect, useState } from "react";
import * as Icons from 'react-feather';
import filePlaceholderImage from '../assets/images/profile-7.jpg';

export function PieceItem(props) {
    const [canShowFileInput, setCanShowFileInput] = useState(true);
    const {piece, type_piece} = props.type_piece;

    useEffect(() => {
        if (!piece || !piece.type_piece_id) return;
        setCanShowFileInput(false);
    }, [piece])
    return (
        <li className="grid grid-cols-12 col-span-12 my-1">
            <div className="col-span-4 pt-2">
                <strong>{type_piece.libelle}</strong>
            </div>
            <div className="col-span-8">
                {!canShowFileInput ? 
                    <div className="w-20 h-20 relative image-fit cursor-pointer zoom-in ml-2">
                        <img className="rounded-md" alt={type_piece.libelle} 
                        src={filePlaceholderImage} />
                        <div title="Retirer" className="tooltip w-5 h-5 flex items-center 
                        justify-center absolute rounded-full text-white bg-theme-6 
                        right-0 top-0 -mr-2 -mt-2"> 
                            <Icons.X className="w-4 h-4" onClick={e => 
                                props.handlePieceDelete(e, props.associeIndex, props.typePieceIndex)}/>
                        </div>
                    </div>
                : 
                <input type="file" className="input w-full border flex-1 field"
                accept="image/*, .pdf, .docx" disabled={props.isDisabled} 
                capture="environment" onChange={e => props.handlePieceUpload(
                    e, props.associeIndex ?? null, type_piece)}/>
                }
            </div>
        </li>
    )
}