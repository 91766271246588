import {all} from 'redux-saga/effects';
import authSaga from "./auth/saga";
import adminsSaga from "./admins/saga";
import rolesSaga from "./roles/saga";
import demandsSaga from "./demands/saga";
import statusDemandsSaga from "./status-demands/saga";
import typeDemandsSaga from "./type-demands/saga";
import clientsSaga from "./clients/saga";
import paymentMethodsSaga from "./payment-methods/saga";
import paymentsSaga from "./payments/saga";
import typeDocumentsSaga from "./type-documents/saga";
import etatDocumentsSaga from "./etat-documents/saga";
import etapeTraitementsSaga from "./etape-traitements/saga";
import etatEtapeTraitementsSaga from "./etat-etape-traitements/saga";
import statusEtatTraitementsSaga from "./status-etat-traitements/saga";
import paysSaga from "./pays/saga";
import categoriesSaga from "./categories/saga";
import articlesSaga from "./articles/saga";
import typePiecesSaga from "./type-pieces/saga";

/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSagas() {
    yield all([
        authSaga(),
        adminsSaga(),
        rolesSaga(),
        demandsSaga(),
        statusDemandsSaga(),
        typeDemandsSaga(),
        clientsSaga(),
        paymentMethodsSaga(),
        paymentsSaga(),
        typeDocumentsSaga(),
        etatDocumentsSaga(),
        etapeTraitementsSaga(),
        etatEtapeTraitementsSaga(),
        statusEtatTraitementsSaga(),
        paysSaga(),
        articlesSaga(),
        categoriesSaga(),
        typePiecesSaga()
    ]);
}
