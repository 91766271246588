import styled from 'styled-components';

const ButtonStyle = styled('button')`
  /* button default style */
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #006699;
  height: 48px;
  width: ${props => (props?.fullwidth ? '100%' : 'auto')};
  color: ${props =>
          props.variant === 'textButton' ||
          props.variant === 'outlined' ||
          props.variant === 'outlinedDash' ||
          props.variant === 'outlinedFab' ||
          props.variant === 'extendedOutlinedFab'
                  ? '#222222'
                  : '#ffffff'};
  border-radius: ${(props) =>
          props?.radius ? props?.radius : '6'}px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  padding: 0 30px;
  border: 0;
  transition: all 0.3s ease;
  box-sizing: border-box;

  span.btn-text {
    padding-left: 4px;
    padding-right: 4px;
    white-space: nowrap;
  }

  span.btn-icon {
    display: flex;

    > div {
      display: flex !important;
    }
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    color: #767676;
    background-color: #E6E6E6;
    border-color: #E6E6E6;

    &:hover {
      color: #767676;
      background-color: #E6E6E6;
      border-color: #E6E6E6;
    }
  }

  /* Material style goes here */

  &.is-material {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  }

  /* When button on loading stage */

  &.is-loading {
    .btn-text {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
`;

ButtonStyle.displayName = 'ButtonStyle';

export default ButtonStyle;
