import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Icons from "react-feather";
import ApiService from "../../api/services/api.service";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';  // Import CSS for Toastify

function CleApi() {
  const [paginatePage, setPaginatePage] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const ELEMENT_PER_PAGE = 20;

  const [inputFields, setInputFields] = useState({
    search: "",
  });

  const [apiKeys, setApiKeys] = useState([]);

  const apiUrl = new ApiService();
  const { id } = useParams();

  useEffect(() => {
    getApiKeys();
  }, [page]);

  const getApiKeys = async () => {
    try {
      const response = await apiUrl._get(
        `admin/organisation/apikey/${id}?page=${page}`
      );
      const { data, success } = response.data;

      if (success) {
        const keys = data;
        setPaginatePage(response.data);
        setApiKeys(keys);
        const PAGE_NUMBER = Math.ceil(response.data.total / ELEMENT_PER_PAGE);
        setTotalPage(PAGE_NUMBER);
      }
    } catch (error) {
      console.error("Error fetching API keys", error);
    }
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    const { search } = inputFields;
    if (search.length >= 3) {
      try {
        const response = await apiUrl._get(
          `admin/organisation/apikey/${id}?search=${search}`
        );
        const { data, success } = response.data;

        if (success) {
          const keys = data;
          setApiKeys(keys);
          // Reset page to 1 when filtering
          setPage(1);
          const PAGE_NUMBER = Math.ceil(response.data.total / ELEMENT_PER_PAGE);
          setTotalPage(PAGE_NUMBER);
        }
      } catch (error) {
        console.error("Error filtering API keys", error);
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(
      () => {
        toast.success("Clé copiée dans le presse-papier !");  // Use Toastify for the success message
      },
      (err) => {
        toast.error("Erreur lors de la copie de la clé !");
        console.error("Could not copy key: ", err);
      }
    );
  };

  // Function to truncate the key
  const truncateKey = (key) => {
    return key ? `${key.substring(0, 10)}...` : "Non défini";
  };

  return (
    <div>
      {/* Toastify Container */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center my-2 justify-between">
        <div className="p-5 text-xl font-medium">Clés API</div>
      </div>

      <div className="intro-y">
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2">
          <div className="box p-2 chat">
            <form className="flex-filter" onSubmit={handleFilterSubmit}>
              <div className="relative text-gray-700 dark:text-gray-300">
                <input
                  type="text"
                  name="search"
                  value={inputFields.search}
                  onChange={handleFormChange}
                  className="input w-56 box ml-1 mr-1 pr-10 placeholder-theme-13"
                  placeholder="Recherche Clé API..."
                />
              </div>
              <button
                type="submit"
                className="button w-45 shadow-md mr-1 px-5 bg-theme-1 text-white flex gap-2"
              >
                Filtrer
                <Icons.Filter className="w-4 h-4 relative my-auto inset-y-0 right-0" />
              </button>
            </form>
          </div>
        </div>

        <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table className="table table-report mt-6">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nom de la Clé</th>
                <th>Statut</th>
                <th>Date d'Expiration</th>
                <th>Clé</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {apiKeys.length > 0 ? (
                apiKeys.map((key, index) => (
                  <tr key={index}>
                    <td>{key.id || "Non défini"}</td>
                    <td>{key.name || "Non défini"}</td>
                    <td>{key.is_status ? "Actif" : "Inactif"}</td>
                    <td>
                      {key.expired_at
                        ? new Date(key.expired_at).toLocaleDateString()
                        : "Non défini"}
                    </td>
                    <td>{truncateKey(key.key)}</td> {/* Display truncated key */}
                    <td>
                      <button
                        className="button bg-theme-1 text-white"
                        onClick={() => handleCopyKey(key.key)}
                      >
                        Copier
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Pas de clés API enregistrées.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className={
              paginatePage.total > ELEMENT_PER_PAGE
                ? "px-5 py-3 flex justify-end text-right border-t border-gray-200"
                : "hidden"
            }
          >
            <button
              onClick={handlePreviousPage}
              type="button"
              className="button flex w-36 items-center border bg-theme-1 text-white"
              disabled={page === 1}
            >
              <Icons.ArrowLeft />
              <span>Précédent</span>
            </button>
            <div className="bg-white text-gray-600 bold mx-5 p-3 box">
              <span>
                {page} / {totalPage}
              </span>
            </div>
            <button
              onClick={handleNextPage}
              type="button"
              className="button flex w-36 items-center border bg-theme-1 text-white"
              disabled={page === totalPage}
            >
              <span>Suivant</span>
              <Icons.ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CleApi;
