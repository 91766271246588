import { useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import * as Icons from 'react-feather';
import { Components } from ".";
import { Utils } from "../utils";

export function SignatureDocumentStep(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const [etat_etape_traitements, setEtat_etape_traitements] = useState([]);
    const [status_etat_traitement, setStatut_etat_traitement] = useState(null);

    const [choice, setChoice] = useState("");
    const [dateLivraison, setDateLivraison] = useState('');
    const [description, setDescription] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleChoiceClick = async (event, value) => {
        event.preventDefault();
        props.useDemande.setIsDisabled(true);

        try {
            const champs_etape_traitements = Utils.String.parseJson(
                props.useDemande?.champs_etape_traitements);

            const signatureDocument = {
                ...champs_etape_traitements,
                "choix-signature": value,
                "date-livraison-signature": dateLivraison
            }
            const payload = {
                champs_etape_traitements: JSON.stringify(signatureDocument)
            };
            const {demande} = await Services.DemandeService.updateChampsEtapeTraitement(
                props.useDemande.id, JSON.stringify(payload));

            setChoice(value);
            props.useDemande.fillDemande(demande);
        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        } finally {
            props.useDemande.setIsDisabled(false);
        }

    }
    
    const handleDateLivraisonSubmit = async event => {
        event.preventDefault();
        props.useDemande.setIsDisabled(true);

        try {
            const champs_etape_traitements = JSON.parse(props.useDemande
                ?.champs_etape_traitements ?? "{}");
            const signatureDocument = {
                ...champs_etape_traitements,
                "date-livraison-signature": dateLivraison
            }
            const payload = {
                champs_etape_traitements: JSON.stringify(signatureDocument)
            };
            const {demande} = await Services.DemandeService.updateChampsEtapeTraitement(
                props.useDemande.id, JSON.stringify(payload));
            
            setIsModalOpen(false);
            props.useDemande.fillDemande(demande);
        } catch (error) {
            if ('messages' in error) return;
            //Toaster
        } finally {
            props.useDemande.setIsDisabled(false);
        }
    }

    const initialize = useCallback(async () => {
        try {
            const champs_etape_traitements = Utils.String.parseJson(props.useDemande
                ?.champs_etape_traitements ?? "{}");

            setDescription(champs_etape_traitements['description-signature'] ?? `Nos vous prions de 
            patienter jusqu'à ce que nos équipes vous communiquent les dispositions 
            à prendre pour la signature de vos documents.`);

            if (choice) {
                const {etat_etape_traitements} = await Services.EtapeTraitementService
                .getAllEtatTraitementsById(props.etapeTraitement.id, abortController.signal);
                setEtat_etape_traitements(etat_etape_traitements);

                const {status_etat_traitement} = await Services.DemandeService
                .getStatutEtatTraitementById(props.useDemande.id, props.etapeTraitement.id, 
                    abortController.signal);
                setStatut_etat_traitement(status_etat_traitement);
            } else {
                setChoice(champs_etape_traitements['choix-signature'] ?? "");
                setDateLivraison(champs_etape_traitements['date-livraison-signature'] ?? '')
            }

        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        } finally {
            setIsDisabled(false);
            props.useDemande.setIsDisabled(false);
        }

      },
      [abortController, choice],
    )
    
    useEffect(() => {
        initialize();
    }, [initialize])

    return (
        <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-10 col-span-12" 
        style={{animationName: "none", opacity: 1, transform: "none"}}>
            {!choice && !isDisabled ? 
                <div className="col-span-12">{description}</div>
                // <>
                //     {   props.useDemande.pays.code !== "MR" ? 
                //         <div className="grid grid-cols-12 gap-5 col-span-12 my-10">
                //             <div className="col-span-12 sm:col-span-6 xxl:col-span-6 box p-5 
                //             cursor-pointer zoom-in bg-theme-2" onClick={e => handleChoiceClick(e, 'bureaux')}>
                //                 <div className="font-medium text-base">
                //                     {props.useDemande.isDisabled ? "Chargement..." : "Je viendrai a vos bureaux"}
                //                 </div>
                //                 <div className="text-gray-600"></div>
                //             </div>
                //             <div className="col-span-12 sm:col-span-6 xxl:col-span-6 box p-5 
                //             cursor-pointer zoom-in bg-theme-2" onClick={e => handleChoiceClick(e, 'livraison')}>
                //                 <div className="font-medium text-base">
                //                     {props.useDemande.isDisabled ? "Chargement..." : "Faites moi parvenir les documents"}
                //                 </div>
                //                 <div className="text-gray-600">Délai estimatif de Livraison : 2 semaines</div>
                //             </div>
                //         </div>  
                //     : 
                //         <div className="col-span-12">{description}</div>
                //         }                 
                // </>
            : null}
            {choice === "livraison" ? 
            <>
                <div className="md:col-span-6 col-span-12">
                    <div className="px-5 md:px-20">
                        {etat_etape_traitements.map((etat_etape_traitement, index) => {
                            return (
                                <div className="intro-x flex items-center my-4" key={index}>
                                    <button className={`w-10 h-10 rounded-full button 
                                    ${status_etat_traitement
                                        ?.etat_etape_traitement_id === etat_etape_traitement.id ? 
                                    "text-white bg-theme-1" : "text-gray-600 bg-gray-200 dark:bg-dark-1"}`}>
                                        {index + 1}
                                    </button>
                                    <div className={`ml-3 text-base ${status_etat_traitement
                                        ?.etat_etape_traitement_id === etat_etape_traitement.id ? 
                                        "text-medium" : "text-gray-700"}`}>
                                        {etat_etape_traitement.libelle}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="md:col-span-6 col-span-12"></div>
                {/* <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                    <button className="button justify-center block bg-theme-5" 
                    onClick={e => handleChoiceClick(e, 'bureaux')}>
                        {props.useDemande.isDisabled ? "Chargement..." : "Je viendrai a vos bureaux"}
                    </button>
                </div> */}
            </>
            : null}
            {choice === "bureaux" ? 
                <div className="grid grid-cols-12 gap-5 col-span-12 my-10">
                    <div className="col-span-12 sm:col-span-4 xxl:col-span-4 box p-5 
                    bg-theme-2" onClick={null}>
                        <Icons.User />
                        <div className="font-medium text-base">Service juridique</div>
                        <div className="text-gray-600">contact@legafrik.com \ +225 0779100653</div>
                    </div>
                    <div className="col-span-12 sm:col-span-4 xxl:col-span-4 box p-5 
                    cursor-pointer zoom-in bg-theme-8" onClick={null}>
                        <Icons.Map />
                        <div className="font-medium text-base">Localisation</div>
                        <div className="text-gray-600">Cliquer pour voir la localisation</div>
                    </div>
                    <div className="col-span-12 sm:col-span-4 xxl:col-span-4 box p-5 
                    cursor-pointer zoom-in bg-theme-12" onClick={e => setIsModalOpen(true)}>
                        <Icons.Calendar />
                        <div className="font-medium text-base">Choisir un jour</div>
                        <div className="text-gray-600">{dateLivraison ? Utils.Date.styleDate(
                            dateLivraison, 'long'): "Cliquer pour choisir un jour"}</div>
                    </div>
                </div>  
            : null}
            {isModalOpen ? 
                <Components.Modal handleModalClose={e => setIsModalOpen(false)}
                title={"Prendre rendez-vous"} isControlVisible={true}
                handleModalValidate={handleDateLivraisonSubmit} 
                isDisabled={props.useDemande.isDisabled}>
                    <div className="intro-y col-span-12">
                        <div className="mb-2">Choisissez la date et l'heure</div>
                        <input type='datetime-local' className="input w-full border flex-1" 
                        name="date-livraison-signature" id="date-livraison-signature" 
                        value={dateLivraison ?? new Date()} required
                        onChange={e => setDateLivraison(e.target.value)}
                        disabled={props.useDemande.isDisabled}/>
                    </div>
                </Components.Modal>
            : null}
        </div>
    )
}