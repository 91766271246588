import { Api } from "./Api";

const ENPOINTS = {
  MoyenPaiement: "moyen-paiements",
};

const getAll = (signal) => {
  return Api.get(ENPOINTS.MoyenPaiement, signal);
};
const getAlls = (signal) => {
  return Api.gets(ENPOINTS.MoyenPaiement, signal);
};
const getById = (id, signal) => {
  return Api.get(`${ENPOINTS.MoyenPaiement}/${id}`, signal);
};

const create = (payload, signal) => {
  return Api.post(ENPOINTS.MoyenPaiement, payload, signal);
};
const creates = (payload, signal) => {
  return Api.posts(ENPOINTS.MoyenPaiement, payload, signal);
};

const update = (id, payload, signal) => {
  return Api.put(`${ENPOINTS.MoyenPaiement}/${id}`, payload, signal);
};
const destroy = (id, signal) => {
  return Api.erase(`${ENPOINTS.MoyenPaiement}/${id}`, signal);
};

export const MoyenPaiementService = {
  getAll,
  getAlls,
  getById,
  create,
  creates,
  update,
  destroy,
};
