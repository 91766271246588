import { Fragment } from "react";
import { Components } from "../..";
import { Utils } from "../../../utils";
import * as Icons from "react-feather";
export function RecaptItem(props) {
  console.log(props);

  const { __ } = Utils.String;

  const renderInfoItemList = (objectValue) => {
    return Object.keys(objectValue).map((item, index) => {
      return (
        <div key={index} className="col-span-6">
          {/* {typeof objectValue[item]} */}
          <Components.InfoItem
            demande={props.demande}
            attribute={__(item)}
            value={objectValue[item]}
          />
        </div>
      );
    });
  };
  const renderValue = (name, anyValue) => {
    if (typeof anyValue === "object") {
      if (anyValue instanceof Array) {
        return anyValue.map((arrayItem, index) => {
          let associe;
          let gerant;
          let président;
          let administration = "";

          if (props.demande === "commerciale" || props.demande === "civile") {
            administration = "administration";
          } else {
            if (name.includes("presidents")) {
              président = "président";
            }
          }
          if (name.includes("associes") && props.demande !== "individuelle") {
            associe = name.slice(0, -2);
            associe = associe.charAt(0).toUpperCase() + associe.substring(1);
          }
          if (name.includes("associes") && props.demande === "individuelle") {
            associe = "Déclarant";
          }
          if (name.includes("gerants")) {
            gerant = name.slice(0, -1);
            gerant = gerant.charAt(0).toUpperCase() + gerant.substring(1);
          }
          return (
            <Fragment key={index}>
              <div
                className="font-medium text-base col-span-12 mb-1 
                                flex justify-between"
              >
                {associe
                  ? `${associe}é`
                  : gerant
                  ? gerant
                  : administration
                  ? administration
                  : président
                  ? président
                  : name.charAt(0).toUpperCase() + name.substring(1)}
                {index + 1}
                {props.hasEditIcon ? (
                  <Icons.Edit
                    onClick={(e) => props.handleEditClick(e, name, index)}
                  />
                ) : null}
              </div>
              {renderInfoItemList(arrayItem)}
            </Fragment>
          );
        });
      } else {
        return renderInfoItemList(props.value);
      }
    }

    if (typeof anyValue === "string")
      return <div className="col-span-6">{__(anyValue)}</div>;
  };

  return (
    <Components.InfoBox
      title={props.title}
      demande={props.demande}
      hasEditIcon={props.hasEditIcon2 ?? false}
      handleEditClick={() =>
        props.handleEditClick(new Event("click"), props.name)
      }
    >
      <div className="grid grid-cols-12 gap-6">
        {renderValue(props.name, props.value)}
      </div>
    </Components.InfoBox>
  );
}
