import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import * as navigations from "./utils/navigation";
import { EditLeads } from "./pages/EditLeads/EditLeads";
import ListAgences from "./pages/Agences/ListAgences";
import ListUtilisateurs from "./pages/AgencesUtilisateur/ListUtilisateurs";
import LogListe from "./pages/Logs/LogListe";
import CleApi from "./pages/CleAPI/CleApi";
import RolesEdit from "./pages/Roles/RolesEdit/RolesEdit";
import EditerMotdDePasse from "./pages/EditerMotsDePasse/EditerMotsDePasse";
import Profile from "./pages/Profile/Profile";
import AdminsDetail from "./pages/Admins/Detail/AdminsDetail";
import RolesCreate from "./pages/Roles/RolesCreate/RoleCreate";


// Admin page
const Login = lazy(() => import("./pages/Login/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const ChangePassword = lazy(() =>
  import("./pages/ChangePassword/ChangePassword")
);
const ForgotPassword = lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword")
);
const AdminLayout = lazy(() => import("./layouts/Layout"));

// Admin page
const LeadList = lazy(() => import("./pages/LeadList/LeadList"));
const DemandsList = lazy(() => import("./pages/DemandsList/DemandsList"));
const AdminsList = lazy(() => import("./pages/AdminsList/AdminsList"));
const RecouvrementList = lazy(() =>
  import("./pages/RecouvrementList/RecouvrementList")
);
const DemandeCreate = lazy(() => import("./pages/DemandeCreate/DemandeCreate"));
const DemandeTypeByCountry = lazy(() =>
  import("./pages/DemandeTypeByCountry/DemandeTypeByCountry")
);
const CustomersList = lazy(() => import("./pages/CustomersList/CustomersList"));
const AdminSettings = lazy(() => import("./pages/AdminSettings/AdminSettings"));
const AdminNotifications = lazy(() =>
  import("./pages/AdminNotifications/AdminNotifications")
);
const AdminsAdd = lazy(() => import("./pages/Admins/Add/AdminsAdd"));
const AdminsEdit = lazy(() => import("./pages/Admins/Edit/AdminsEdit"));
const DemandsPayment = lazy(() =>
  import("./pages/DemandsPayment/DemandsPayment")
);
const DemandsCreatePayment = lazy(() =>
  import("./pages/DemandsCreatePayment/DemandsCreatePayment")
);
//Demands
const DetailLeads = lazy(() => import("./pages/DetailLeads/DetailLeads"));
const DemandsDetail = lazy(() => import("./pages/DetailDemands/DetailDemands"));
const EditQuestionnaire = lazy(() =>
  import("./pages/EditQuestionnaire/EditQuestionnaire")
);

/* Organisation / organisation */

//Processing
const DocumentProcessingStep = lazy(() =>
  import("./pages/DocumentProcessingStep/DocumentProcessingStep")
);

//Roles list
const RolesList = lazy(() => import("./pages/RolesList/RolesList"));

//Admin change password
const AdminChangePassword = lazy(() =>
  import("./pages/AdminChangePassword/AdminChangePassword")
);

//Articles
const ArticleList = lazy(() => import("./pages/ArticleList/ArticleList"));
const Articles = lazy(() => import("./pages/Articles/Add/ArticlesAdd"));
const ArticlesEdit = lazy(() => import("./pages/Articles/Edit/ArticlesEdit"));
const ArticlesDetail = lazy(() =>
  import("./pages/Articles/Detail/ArticlesDetail")
);


const DemandeOrganisations = lazy(() =>
  import("./pages/Organisations/ListOrganisation")
);
const Transaction = lazy(() => import("./pages/Transaction/ListTransaction"));
const Vente = lazy(() => import("./pages/Ventes/ListeVents"));

// const CleAPI = lazy(() => import("./pages/CleAPI/CleApi"));

const LegafrikRoutes = () => {
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route
            element={
              <AdminLayout>
                <Dashboard />
              </AdminLayout>
            }
            path={navigations.DASHBOARD}
            exact
          />
          <Route
            path={navigations.ROLES_EDIT}
            element={
              <AdminLayout>
                <RolesEdit />
              </AdminLayout>
            }
          />
          <Route
            path={navigations.ROLES_EDIT}
            element={
              <AdminLayout>
                <RolesEdit />
              </AdminLayout>
            }
          /><Route
            path={navigations.ROLES_CREATE}
            element={
              <AdminLayout>
                <RolesCreate />
              </AdminLayout>
            }
          />
          <Route
            element={
              <AdminLayout>
                <AdminNotifications />
              </AdminLayout>
            }
            path={navigations.ADMIN_NOTIFICATION}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminSettings />
              </AdminLayout>
            }
            path={navigations.ADMIN_SETTINGS}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <CustomersList />
              </AdminLayout>
            }
            path={navigations.CUSTOMERS_LIST}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <RecouvrementList />
              </AdminLayout>
            }
            path={navigations.RECOUVREMENT_LIST}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandsList />
              </AdminLayout>
            }
            path={navigations.DEMANDS_LIST}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <LogListe />
              </AdminLayout>
            }
            path={navigations.LOGS}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <CleApi />
              </AdminLayout>
            }
            path={navigations.CLE_API}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <EditerMotdDePasse />
              </AdminLayout>
            }
            path={navigations.EDIT_MOTS_DE_PASSE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <Profile />
              </AdminLayout>
            }
            path={navigations.EDITER_PROFILE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <Transaction />
              </AdminLayout>
            }
            path={navigations.TRANSACTIONS}
            exact
          />

          <Route
            element={
              <AdminLayout>
                <Vente />
              </AdminLayout>
            }
            path={navigations.VENTES}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <LeadList />
              </AdminLayout>
            }
            path={navigations.LEADS_LIST_TYPE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminsList />
              </AdminLayout>
            }
            path={navigations.ADMINS_LIST}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminsAdd />
              </AdminLayout>
            }
            path={navigations.ADMINS_ADD}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminsEdit />
              </AdminLayout>
            }
            path={navigations.ADMINS_EDIT}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminsEdit />
              </AdminLayout>
            }
            path={navigations.ADMINS_DETAIL_EDIT}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminsDetail />
              </AdminLayout>
            }
            path={navigations.ADMINS_DETAIL}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DetailLeads />
              </AdminLayout>
            }
            path={navigations.DETAIL_LEADS}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandsDetail />
              </AdminLayout>
            }
            path={navigations.DEMANDS_DETAIL}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <EditLeads />
              </AdminLayout>
            }
            path={navigations.EDIT_LEADS}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <EditQuestionnaire />
              </AdminLayout>
            }
            path={navigations.EDIT_QUESTIONNAIRE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandsPayment />
              </AdminLayout>
            }
            path={navigations.DEMANDS_PAYMENT}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandsCreatePayment />
              </AdminLayout>
            }
            path={navigations.DEMANDS_CREATE_PAYMENT}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DocumentProcessingStep />
              </AdminLayout>
            }
            path={navigations.DOCUMENT_PROCESSING_STEP}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <RolesList />
              </AdminLayout>
            }
            path={navigations.ROLES_LIST}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <AdminChangePassword />
              </AdminLayout>
            }
            path={navigations.ADMIN_CHANGE_PASSWORD}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <ArticleList />
              </AdminLayout>
            }
            path={navigations.ARTICLE_LIST}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <Articles />
              </AdminLayout>
            }
            path={navigations.ARTICLE_ADD}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <ArticlesEdit />
              </AdminLayout>
            }
            path={navigations.ARTICLE_EDIT}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <ArticlesDetail />
              </AdminLayout>
            }
            path={navigations.ARTICLE_DETAIL}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandeCreate />
              </AdminLayout>
            }
            path={navigations.DEMANDE_CREATE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandeTypeByCountry />
              </AdminLayout>
            }
            path={navigations.DEMANDE_TYPE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandeOrganisations />
              </AdminLayout>
            }
            path={navigations.DEMANDE_ORGANISATION}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <DemandeOrganisations />
              </AdminLayout>
            }
            path={navigations.ORGANISATION_CREATE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <ListAgences />
              </AdminLayout>
            }
            path={navigations.LISTE_AGENCE}
            exact
          />
          <Route
            element={
              <AdminLayout>
                <ListUtilisateurs />
              </AdminLayout>
            }
            path={navigations.LISTE_UTILISATEUR}
            exact
          />
        </Route>
        <Route exact path={navigations.LOGIN} element={<Login />} />
        <Route
          exact
          path={navigations.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route
          exact
          path={navigations.CHANGE_PASSWORD}
          element={<ChangePassword />}
        />
      </Routes>
    </Suspense>
  );
};

export default LegafrikRoutes;
