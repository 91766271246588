export const countryCodes = [
    { 
      code: "+212", 
      label: "Maroc"
    },
    { 
      code: "+225", 
      label: "Côte d'Ivoire"
    },
    { 
      code: "+237", 
      label: "Cameroun"
    },
    { 
      code: "+224", 
      label: "Guinée"
    },
    { 
      code: "+228", 
      label: "Togo"
    },
    { 
      code: "+243", 
      label: "RDC" // République Démocratique du Congo
    },
    { 
      code: "+229", 
      label: "Bénin"
    },
    { 
      code: "+226", 
      label: "Burkina Faso"
    },
    { 
      code: "+221", 
      label: "Sénégal"
    },
  ];
  