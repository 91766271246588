import { SARL } from "./sarl";
import { SARLU } from "./sarlu";
import { SAS } from "./sas";
import { SASU } from "./sasu";
import { ONG } from "./ong";
import { DOMICILIATION } from "./domiciliation";
import { DEPOT_MARQUE } from "./depot_marque";
import { ASSOCIATION } from "./association";
import { FONDATION } from "./fondation";
import { RECOUVREMENT } from "./recouvrement";
import { MODIFICATION_STATUTAIRE } from "./modification_statutaire";
import {SCI} from './sci'

export const TYPES_DEMANDE_CI = {
    SARL, 
    SARLU, 
    SAS, 
    SASU,
    SCI,
    ONG, 
    DOMICILIATION,
    DEPOT_MARQUE,
    ASSOCIATION,
    FONDATION,
    RECOUVREMENT,
    MODIFICATION_STATUTAIRE
}
