import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Components } from ".";
import { useParams } from "react-router-dom";
import { Hooks } from "../hooks";
import { Data } from "../data";
import { Services } from "../services";

import * as Icons from "react-feather";

export function DemandeVerification() {
  const abortController = useMemo(() => new AbortController(), []);

  const { ChampsDemande } = useMemo(() => Data, []);

  const { id } = useParams();
  const [pays, setPays] = useState("");
  const [champsDemandeData, setChampsDemandeData] = useState([]);
  const [champsDemandeObject, setChampsDemandeObject] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newChampsDemandeData, setNewChampsDemandeData] = useState([]);
  const [leadDemande, setLeadDemande] = useState({});
  const [step, setStep] = useState(0);

  const useTypeDemande = Hooks.useTypeDemande();
  const useDemande = Hooks.useDemande();
//   const usePaiement = Hooks.usePaiement();
  const [choice, setChoice] = useState("");
//   const [lastChoice, setLastChoice] = useState("");

  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isOther, setIsOther] = useState(false);

  const handleNextClick = (e, name, value) => {
    // if (!choice || choice === "" || choice === "Autres") {
    //     alert('Vous devez entrer votre choix');
    //     return;
    // }
    e.preventDefault();

    let inputArray = Array.from(document.querySelectorAll(`[name=${name}]`));

    if ((!value || value === "") && inputArray[0].value !== "")
      value = inputArray[0].value;

    if (
      inputArray[0].type === "radio" &&
      (!value || value === "" || value === "on")
    ) {
      const input = inputArray.find((inputElem) => inputElem.checked === true);
      value = input?.nextElementSibling?.innerText ?? "";
    }

    if (value === "Autres") {
      value = document.getElementById("input-main").value;
    }

    if (!value || value === "" || value === "Autres") {
      alert("Vous devez entrer votre choix");
      return;
    }

    setIsModalOpen(false);

    if (step !== newChampsDemandeData.length - 1) {
      const champsDemandeObjectCopy1 = { ...champsDemandeObject };
      champsDemandeObjectCopy1[name] = value;

      setChampsDemandeObject(champsDemandeObjectCopy1);
      setStep(step + 1);
    } else {
      const champsDemandeObjectCopy = { ...champsDemandeObject };
      champsDemandeObjectCopy[name] = value;

      handleValidateClick(champsDemandeObjectCopy);
    }

    // setLastChoice(choice);

    //  setChoice('');
    setIsOther(false);
  };

  const handleValidateClick = async (value) => {
    setIsLoading(true);

    if (useDemande.isDisabled) return;
    useDemande.setIsDisabled(true);

    try {
      const payload = {
        type_demande_id: useDemande.type_demande_id,
        dossier_id: useDemande.dossier_id,
        pays_id: useDemande.pays_id,
        champs_demande: JSON.stringify(value),
        montant_total: useDemande.montant_total,
      };

      await Services.DemandeService.update(
        id,
        JSON.stringify(payload),
        abortController.signal
      );

      window.location.reload();
    } catch (error) {
      useDemande.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();

    // setChoice(lastChoice);

    if (step === 0 || useDemande.isDisabled) return;

    setStep(step - 1);
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false);
    // setMoyen_paiement('');
  };

  const handleChoiceChange = (value) => {
    if (value === "Autres") {
      setIsOther(true);
      setChoice("");
    } else {
      if (
        // value.includes("A mon domicile") ||
        value.includes("Je n'ai pas de siège")
      ) {
        setIsModalOpen(true);
        setModalTitle(value);
        // if (value.includes("A mon domicile")) {
        //   setModalContent(
        //     "Nous aurons obligatoirement besoin de votre contrat de Bail, ou l attestation de propriété avec la mention du nom du propriétaire"
        //   );
        // } else
         if (value.includes("Je n'ai pas de siège")) {
          setModalContent(
            "Vous devez obligatoirement trouver un siège pour les formalités de créations de votre entreprise"
          );
        }
      }

      setIsOther(false);
      setChoice(value);
    }
  };

  const initialize = useCallback(async () => {
    try {
      const { demande } = await Services.DemandeService.getById(
        id,
        abortController.signal
      );

      const new_champs_demande = JSON.parse(demande.champs_demande);

      setLeadDemande(new_champs_demande);

      await useDemande.getDemande(id, abortController.signal);

      await useDemande.setType_demande_id(demande.type_demande_id);
      const { pays } = await useTypeDemande.getTypeDemande(
        demande.type_demande_id,
        abortController.signal
      );

      setPays(pays);
    } catch (error) {
      if ("messages" in error) return;
      //toast
    }
  }, [abortController, id]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const champsDemandeDataRef = useRef([]);
  champsDemandeDataRef.current = champsDemandeData;

  useEffect(() => {
    if (!pays.code) return;

    const LIBELLE = useTypeDemande.libelle;
    const CODE_PAYS = pays.code.toUpperCase();

    if (LIBELLE.includes("SARL"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARL]);
    if (LIBELLE.includes("SARLU"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SUARL"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SAS"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);
    if (LIBELLE.includes("SASU"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SASU]);
    if (LIBELLE.includes("ONG"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ONG]);
    if (LIBELLE.includes("Association"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ASSOCIATION]);
    if (LIBELLE.includes("Domiciliation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DOMICILIATION]);
    if (LIBELLE.includes("Marque"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DEPOT_MARQUE]);

    setStep(0);
  }, [useTypeDemande.libelle, ChampsDemande, pays.code, leadDemande]);

  useEffect(() => {
    const newChampsDemandeData = [];
    const champsDemandeObjectCopy = { ...champsDemandeObject };

    for (const [key, value] of Object.entries(leadDemande)) {
      if (value === "") {
        const objetB = champsDemandeData.find((obj) => obj.name === key);
        if (objetB) {
          newChampsDemandeData.push(objetB);
        }
      } else {
        champsDemandeObjectCopy[key] = value;

        setChampsDemandeObject(champsDemandeObjectCopy);
      }
    }

    setNewChampsDemandeData(newChampsDemandeData);
  }, [champsDemandeData, leadDemande]);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">
        Terminer la commande "{useTypeDemande.libelle} {pays.libelle}"
      </h2>
      <div className="intro-y box py-10 sm:py-20 mt-">
        <div className="hidden sm:flex justify-center">
          {newChampsDemandeData.map((newChampsDemandeDataItem, index) => {
            // if (!newChampsDemandeDataItem.question || !newChampsDemandeDataItem.description) {
            //     return false;
            // }
            return (
              <div key={index} className={step !== index ? "hidden" : ""}>
                <div className="px-5 mt-10">
                  <div className="font-medium text-center text-lg">
                    {newChampsDemandeDataItem.question}
                  </div>
                  <div className="text-gray-600 text-center mt-2">
                    {newChampsDemandeDataItem.description}
                  </div>
                </div>
                <div
                  className={`px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                    dark:border-dark-5 demande-step`}
                >
                  <div className="flex flex-col max-w-md mx-auto">
                    {newChampsDemandeDataItem.options.map(
                      (option, optionIndex) => {
                        return (
                          <Fragment key={optionIndex}>
                            <Components.DemandeStepOptionItem
                              itemName={newChampsDemandeDataItem.name}
                              itemValue={option.value}
                              handleChoiceChange={() =>
                                handleChoiceChange(option.value)
                              }
                            />
                            {isModalOpen ? (
                              <Components.ModalCustom
                                handleModalClose={handleModalClose}
                                title={modalTitle}
                                handleModalValidate={null}
                              >
                                <div>
                                  <div className="flex items-center justify-center sm:justify-center">
                                    <Icons.Info className="w-10 h-10 " />
                                  </div>

                                  {modalContent}
                                  <div className="col-span-12 flex items-center justify-center sm:justify-end mt-10">
                                    <button
                                      className="button w-24 justify-center block bg-theme-1 
                                        text-white ml-2"
                                      onClick={(e) =>
                                        handleNextClick(
                                          e,
                                          newChampsDemandeDataItem.name,
                                          e.target.value
                                        )
                                      }
                                    >
                                      Suivant
                                    </button>
                                  </div>
                                </div>
                              </Components.ModalCustom>
                            ) : null}
                          </Fragment>
                        );
                      }
                    )}
                    {newChampsDemandeDataItem.type === "simple" || isOther ? (
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <input
                          type="text"
                          className="input w-full border flex-1"
                          id="input-main"
                          name={newChampsDemandeDataItem.name}
                          placeholder="Entrez votre reponse"
                          onChange={(e) => setChoice(e.target.value)}
                        />
                      </div>
                    ) : null}
                    <div
                      className="intro-y col-span-12 flex items-center justify-center
                                sm:justify-end mt-10"
                    >
                      {step > 0 ? (
                        <button
                          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                                        dark:bg-dark-1 dark:text-gray-300"
                          onClick={handleBackClick ?? ""}
                        >
                          Revenir
                        </button>
                      ) : null}
                      {step !== newChampsDemandeData.length - 1 ? (
                        <button
                          className="button w-24 justify-center block bg-theme-1 
                                text-white ml-2"
                          onClick={(e) =>
                            handleNextClick(
                              e,
                              newChampsDemandeDataItem.name,
                              e.target.value
                            )
                          }
                        >
                          Suivant
                        </button>
                      ) : (
                        <button
                          className="button w-24 justify-center block bg-theme-1 
                                text-white ml-2"
                          onClick={(e) =>
                            handleNextClick(
                              e,
                              newChampsDemandeDataItem.name,
                              e.target.value
                            )
                          }
                          disabled={isLoading}
                        >
                          Ajouter
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
