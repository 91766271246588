import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { rolesListRequest, createRoleRequest } from "../../../store/roles/actions";
// import { rolesSelector } from "../../../store/roles/selectors";
import { useNavigate } from "react-router-dom";  // Importation du hook useNavigate
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormGroup, FormGroupedSurround, FormLabel, StyledError } from "../../../components/StyledErro";

import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { AdminFormWrapper } from "../../Admins/Admins.style";
import { FormModal } from "../../../components/FormModal/FormModal";
import { hideModal } from "../../../store/modal/actions";

const RoleCreationValidationSchema = Yup.object().shape({
    libelle: Yup.string().required("Le nom du rôle est requis"),
    permissions: Yup.array().min(1, "Au moins une permission est requise"),
});

const RolesCreateForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();  // Initialisation de useNavigate
    // const useRoles = rolesSelector(useSelector((state) => state));
    const [isSubmitting, setIsSubmitting] = useState(false); // Ajout du state isSubmitting


    const initialValues = {
        libelle: "",
        permissions: [],
    };

    const handleSubmit = async (values) => {
        setIsSubmitting(true); // Activer l'état d'envoi

        try {
            await dispatch(createRoleRequest(values));
            toast.success("Rôle créé avec succès!");
            navigate("/roles-list");
        } catch (error) {
            toast.error("Échec de la création du rôle.");
        } finally {
            setIsSubmitting(false); // Désactiver l'état d'envoi
        }
    };


    useEffect(() => {
        dispatch(rolesListRequest());
    }, [dispatch]);

    const PermissionGroup = ({ title, permissions }) => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <div className="permission-group">
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: "pointer", fontWeight: "bold", marginBottom: "10px" }}
                >
                    {title} {isOpen ? "-" : "+"}
                </div>
                {isOpen && (
                    <div className="permissions-list" style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {permissions.map((permission) => (
                            <div key={permission.value} style={{ display: "inline-block", marginRight: "10px" }}>
                                <Field type="checkbox" name="permissions" value={permission.value} />
                                <label htmlFor={permission.value} style={{ marginLeft: "5px" }}>{permission.label}</label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    // Grouped Permissions
    const groupedPermissions = {
        creation: [{ value: "create-admin", label: "Créer admin" },
        { value: "create-categorieuser", label: "Créer catégorie utilisateur" },
        { value: "create-codepromo", label: "Créer code promo" },
        { value: "create-demande", label: "Créer demande" },
        { value: "create-document", label: "Créer document" },
        { value: "create-dossier", label: "Créer dossier" },
        { value: "create-etapetraitement", label: "Créer étape traitement" },
        { value: "create-etatdemande", label: "Créer état demande" },
        { value: "create-etatdocument", label: "Créer état document" },
        { value: "create-moyenpaiement", label: "Créer moyen de paiement" },
        { value: "create-paiement", label: "Créer paiement" },
        { value: "create-pays", label: "Créer pays" },
        { value: "create-permission", label: "Créer permission" },
        { value: "create-piece", label: "Créer pièce" },
        { value: "create-role", label: "Créer rôle" },
        { value: "create-typedemande", label: "Créer type demande" },
        { value: "create-typedocument", label: "Créer type document" },
        { value: "create-typepaiement", label: "Créer type paiement" },
        { value: "create-typepiece", label: "Créer type pièce" },
        { value: "create-user", label: "Créer utilisateur" },
        { value: "create-message", label: "Créer message" },
        { value: "create-etatetapetraitement", label: "Créer état étape traitement" },
        { value: "create-statusetattraitement", label: "Créer statut état traitement" },
        { value: "create-observation", label: "Créer observation" },
        { value: "create-attribution", label: "Créer attribution" }],
        modification: [{ value: "update-admin", label: "Modifier admin" },
        { value: "update-categorieuser", label: "Modifier catégorie utilisateur" },
        { value: "update-codepromo", label: "Modifier code promo" },
        { value: "update-demande", label: "Modifier demande" },
        { value: "update-document", label: "Modifier document" },
        { value: "update-dossier", label: "Modifier dossier" },
        { value: "update-etapetraitement", label: "Modifier étape traitement" },
        { value: "update-etatdemande", label: "Modifier état demande" },
        { value: "update-etatdocument", label: "Modifier état document" },
        { value: "update-moyenpaiement", label: "Modifier moyen de paiement" },
        { value: "update-paiement", label: "Modifier paiement" },
        { value: "update-pays", label: "Modifier pays" },
        { value: "update-permission", label: "Modifier permission" },
        { value: "update-piece", label: "Modifier pièce" },
        { value: "update-role", label: "Modifier rôle" },
        { value: "update-typedemande", label: "Modifier type demande" },
        { value: "update-typedocument", label: "Modifier type document" },
        { value: "update-typepaiement", label: "Modifier type paiement" },
        { value: "update-typepiece", label: "Modifier type pièce" },
        { value: "update-user", label: "Modifier utilisateur" },
        { value: "update-message", label: "Modifier message" },
        { value: "update-etatetapetraitement", label: "Modifier état étape traitement" },
        { value: "update-statusetattraitement", label: "Modifier statut état traitement" },
        { value: "update-observation", label: "Modifier observation" },
        { value: "update-attribution", label: "Modifier attribution" }],
        suppression: [{ value: "delete-admin", label: "Supprimer admin" },
        { value: "delete-categorieuser", label: "Supprimer catégorie utilisateur" },
        { value: "delete-codepromo", label: "Supprimer code promo" },
        { value: "delete-demande", label: "Supprimer demande" },
        { value: "delete-document", label: "Supprimer document" },
        { value: "delete-dossier", label: "Supprimer dossier" },
        { value: "delete-etapetraitement", label: "Supprimer étape traitement" },
        { value: "delete-etatdemande", label: "Supprimer état demande" },
        { value: "delete-etatdocument", label: "Supprimer état document" },
        { value: "delete-moyenpaiement", label: "Supprimer moyen de paiement" },
        { value: "delete-paiement", label: "Supprimer paiement" },
        { value: "delete-pays", label: "Supprimer pays" },
        { value: "delete-permission", label: "Supprimer permission" },
        { value: "delete-piece", label: "Supprimer pièce" },
        { value: "delete-role", label: "Supprimer rôle" },
        { value: "delete-typedemande", label: "Supprimer type demande" },
        { value: "delete-typedocument", label: "Supprimer type document" },
        { value: "delete-typepaiement", label: "Supprimer type paiement" },
        { value: "delete-typepiece", label: "Supprimer type pièce" },
        { value: "delete-user", label: "Supprimer utilisateur" },
        { value: "delete-message", label: "Supprimer message" },
        { value: "delete-etatetapetraitement", label: "Supprimer état étape traitement" },
        { value: "delete-statusetattraitement", label: "Supprimer statut état traitement" },
        { value: "delete-observation", label: "Supprimer observation" },
        { value: "delete-attribution", label: "Supprimer attribution" }],
        visualisation: [{ value: "view-any-admin", label: "Voir tout admin" },
        { value: "view-any-categorieuser", label: "Voir toute catégorie utilisateur" },
        { value: "view-any-codepromo", label: "Voir tout code promo" },
        { value: "view-any-demande", label: "Voir toute demande" },
        { value: "view-any-document", label: "Voir tout document" },
        { value: "view-any-dossier", label: "Voir tout dossier" },
        { value: "view-any-etapetraitement", label: "Voir toute étape traitement" },
        { value: "view-any-etatdemande", label: "Voir tout état demande" },
        { value: "view-any-etatdocument", label: "Voir tout état document" },
        { value: "view-any-moyenpaiement", label: "Voir tout moyen de paiement" },
        { value: "view-any-paiement", label: "Voir tout paiement" },
        { value: "view-any-pays", label: "Voir tout pays" },
        { value: "view-any-permission", label: "Voir toute permission" },
        { value: "view-any-piece", label: "Voir toute pièce" },
        { value: "view-any-role", label: "Voir tout rôle" },
        { value: "view-any-typedemande", label: "Voir tout type demande" },
        { value: "view-any-typedocument", label: "Voir tout type document" },
        { value: "view-any-typepaiement", label: "Voir tout type paiement" },
        { value: "view-any-typepiece", label: "Voir tout type pièce" },
        { value: "view-any-user", label: "Voir tout utilisateur" },
        { value: "view-any-message", label: "Voir tout message" },
        { value: "view-any-etatetapetraitement", label: "Voir tout état étape traitement" },
        { value: "view-any-statusetattraitement", label: "Voir tout statut état traitement" },
        { value: "view-any-observation", label: "Voir toute observation" },
        { value: "view-any-attribution", label: "Voir toute attribution" }]
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={RoleCreationValidationSchema}
        >
            {({ values, handleChange, handleBlur }) => (
                <Form className="legafrik-form-parent">
                    <FormGroup>
                        <FormLabel htmlFor="libelle">Nom du rôle :</FormLabel>
                        <Field name="libelle">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    id="libelle"
                                    type="text"
                                    value={values.libelle}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Nom du rôle"
                                    className="border text-gray-700 dark:border-dark-5 dark:text-gray-300 p-2 rounded w-full"
                                />
                            )}
                        </Field>
                        <ErrorMessage name="libelle" component={StyledError} />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Permissions :</FormLabel>
                        <ErrorMessage name="permissions" component={StyledError} />
                    </FormGroup>

                    <FormGroupedSurround>
                        <FormGroup>
                            <PermissionGroup title="Création" permissions={groupedPermissions.creation} />
                        </FormGroup>
                        <FormGroup>
                            <PermissionGroup title="Modification" permissions={groupedPermissions.modification} />
                        </FormGroup>
                    </FormGroupedSurround>

                    <FormGroupedSurround>
                        <FormGroup>
                            <PermissionGroup title="Suppression" permissions={groupedPermissions.suppression} />
                        </FormGroup>
                        <FormGroup>
                            <PermissionGroup title="Visualisation" permissions={groupedPermissions.visualisation} />
                        </FormGroup>
                    </FormGroupedSurround>

                    <div className="legafrik-form-btn-box">
                        <div className="legafrik-form-info-box-item">
                            <Button
                                type={'submit'}
                                title={'Créer le rôle'}
                                disabled={isSubmitting} // Désactivation du bouton pendant la soumission via useState

                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const RolesCreate = () => {
    const dispatch = useDispatch();
    const useModal = useSelector((state) => state.modal);
    // const useRoles = useSelector((state) => state.roles);

    useEffect(() => {
        dispatch(rolesListRequest());
    }, [dispatch]);

    return (
        <AdminFormWrapper>
            <div className="form-header">
                <h3 className="form-header-title">Création d'un rôle</h3>
            </div>
            <RolesCreateForm />
            {useModal.isOpenConfirmedArticleAdd && (
                <FormModal isControlVisible={true} handleModalClose={() => dispatch(hideModal())}>
                    <div className="legafrik-confirm-body">
                    </div>

                    <div className="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5">
                        <button
                            type="button"
                            className="button border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
                            onClick={() => dispatch(hideModal())}
                        >
                            Fermer
                        </button>
                    </div>
                </FormModal>
            )}
            <ToastContainer />
        </AdminFormWrapper>
    );
};

export default RolesCreate;
