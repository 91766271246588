import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Icons from "react-feather";
import ApiService from "../../api/services/api.service";

function ListAgences() {
  const [paginatePage, setPaginatePage] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const ELEMENT_PER_PAGE = 20;

  const [inputFields, setInputFields] = useState({
    denomination: "",
    numero_demande: "",
    pays_id: "",
  });

  const [listAgences, setListAgences] = useState([]);

  const apiUrl = new ApiService();

  const { id } = useParams();

  useEffect(() => {
    getListAgence();
  }, []);

  const getListAgence = async () => {
    await apiUrl._get(`admin/organisation/agences/${id}`).then((response) => {
      const { agences } = response.data;
      console.log("agences", response.data);
      setPaginatePage(response.data);

      setListAgences(agences);
      const PAGE_NUMBER = Math.ceil(response.data.total / ELEMENT_PER_PAGE);
      setTotalPage(PAGE_NUMBER);
    });
  };

  const handlePreviousPage = async () => {
    // setLoading(true);
    if (totalPage > paginatePage.current_page) {
      page > 0 ? setPage(page - 1) : setPage(1);
      await apiUrl
        ._get(`admin/organisation/agences/${id}?page=${page}`)
        .then((response) => {
          const { agences } = response.data;
          // setPaginatePage(response.data);
          // console.log("handlePreviousPage", response.data);
          setListAgences(agences);
          // setListOrganisation(response.data.organisations);
        });
      // const datas = await previousPage(params);

      // setdataUsers(datas);
      // dataHistory.previous === null
      //   ? setIsDisabled(true)
      //   : setIsDisabled(false);
    }
    // setLoading(false);
  };

  const handleNextPage = async () => {
    // setLoading(true);
    setPage(page + 1);
    await apiUrl
      ._get(`admin/organisation/agences/${id}?page=${page}`)
      .then((response) => {
        const { agences } = response.data;

        setListAgences(agences);
      });
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value.length >= 3) {
      await apiUrl
        ._get(`admin/organisation/agences/${id}?name=${value}`)
        .then((response) => {
          const { agences } = response.data;

          setListAgences(agences);
        });
    }
    // console.log("handleFilterSubmit");
  };

  const handleFormChange = async (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      await apiUrl
        ._get(`admin/organisation/agences/${id}?name=${value}`)
        .then((response) => {
          const { agences } = response.data;

          setListAgences(agences);
        });
    } else {
      getListAgence();
    }
  };

  return (
    <div>
      <div
        className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center my-2
                  justify-between"
      >
        <div className="p-5 text-xl  font-medium ">Liste des Agences</div>
      </div>

      <div className="intro-y">
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2">
          <div className="box p-2 chat">
            <form className="flex-filter" onSubmit={handleFilterSubmit}>
              <div className=" relative text-gray-700 dark:text-gray-300">
                <input
                  type="text"
                  name="reachercher"
                  value={inputFields.denomination}
                  onChange={handleFormChange}
                  className="input w-56 box ml-1 mr-1 pr-10 placeholder-theme-13"
                  placeholder="Recherche..."
                />
              </div>
              <button className="button w-45 shadow-md mr-1 px-5  bg-theme-1 text-white flex gap-2">
                Filtrer
                <Icons.Filter className="w-4 h-4 relative my-auto inset-y-0  right-0" />
              </button>
            </form>
          </div>
        </div>

        <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table className="table table-report mt-6">
            <thead>
              <tr className="">
                <th>Dénomination</th>
                <th>Ville</th>
                <th>Commune</th>
                <th className="">Quartier</th>
                <th className="">Contact</th>
                <th className="">Mail</th>
              </tr>
            </thead>
            <tbody>
              {listAgences &&
                listAgences.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p className="legafrik-list-data-text">
                        {item.name ? item.name : "non definit"}
                      </p>
                    </td>
                    <td>
                      <p className="legafrik-list-data-text">{item.ville}</p>
                    </td>
                    <td>
                      <p className="legafrik-list-data-text">{item.adresse}</p>
                    </td>
                    <td>
                      <p className="legafrik-list-data-text">
                        {item.adresse_detail}
                      </p>
                    </td>
                    <td>
                      <p className="legafrik-list-data-text">{item.phone}</p>
                    </td>
                    <td>
                      <p className="legafrik-list-data-text">{item.email}</p>
                    </td>
                  </tr>
                ))}
              {!listAgences.length ? (
                <tr>
                  <td className="text-xl text-center col-12">
                    Pas d'agence enregistrer
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <div
            className={
              paginatePage.total > 20
                ? "px-5 py-3 flex justify-end text-right border-t border-gray-200 "
                : "hidden"
            }
          >
            <button
              onClick={handlePreviousPage}
              type="button"
              className="button  flex w-36 items-center  border bg-theme-1 text-white "
              disabled={paginatePage.current_page === 1}
            >
              <span>
                <Icons.ArrowLeft />
              </span>
              <span> Precedent </span>
            </button>
            <div className="bg-white text-gray-600 bold mx-5 p-3 box ">
              {listAgences ? (
                <span>
                  {paginatePage.current_page} / {totalPage}
                </span>
              ) : (
                0
              )}
            </div>
            <button
              onClick={handleNextPage}
              type="button"
              className="button  flex w-36 items-center  border bg-theme-1 text-white"
              disabled={totalPage === paginatePage.current_page}
            >
              <span> Suivant </span>
              <span>
                <Icons.ArrowRight />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListAgences;
