import { Link } from "react-router-dom";

export function AnalyticsCard(props) {
    return (
        <div className="col-span-12 sm:col-span-4 intro-y mb-5 sm:mb-0">
            <Link to={props.linkTo ?? "/"}>
                <div className="report-box zoom-in">
                    <div className="box p-5">
                        <div className="md:text-2xl text-lg font-bold leading-8 mt-6">
                            {props.name}
                        </div>
                        <div className="text-base text-gray-600 mt-4">{props.number ?? ""}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}