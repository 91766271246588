import { CategorieUserForm } from "./forms/CategorieUserForm";
import { UserForm } from "./forms/UserForm";
import { RoleForm } from "./forms/RoleForm";
import { PermissionForm } from "./forms/PermissionForm";
import { DossierForm } from "./forms/DossierForm";
import { PaysForm } from "./forms/PaysForm";
import { PasswordForm } from "./forms/PasswordForm";
import { EtatDemandeForm } from "./forms/EtatDemandeForm";
import { EtapeTraitementForm } from "./forms/EtapeTraitementForm";
import { TypePaiementForm } from "./forms/TypePaiementForm";
import { MoyenPaiementForm } from "./forms/MoyenPaiementForm";
import { DemandeForm } from "./forms/DemandeForm";
import { CodePromoForm } from "./forms/CodePromoForm";
import { PaiementForm } from "./forms/PaiementForm";
import { TypeDocumentForm } from "./forms/TypeDocumentForm";
import { EtatDocumentForm } from "./forms/EtatDocumentForm";
import { DocumentForm } from "./forms/DocumentForm";
import { TypePieceForm } from "./forms/TypePieceForm";
import { PieceForm } from "./forms/PieceForm";
import { MobileMenu } from "./MobileMenu";
import { SideNav } from "./SideNav";
import { TopBar } from "./TopBar";
import { LoginForm } from "./forms/LoginForm";
import { RegisterForm } from "./forms/RegisterForm";
import { ErrorMessages } from "./ErrorMessages";
import { AnalyticsCard } from "./AnalyticsCard";
import { BreadCrump } from "./BreadCrump";
import { Table } from "./Table";
import { Dropdown } from "./Dropdown";
import { DropdownBox } from "./DropdownBox";
import { DropdownItem } from "./DropdownItem";
import { NewDemandeDropdownButton } from "./NewDemandeDropdownButton";
import { InfoItem } from "./InfoItem";
import { InfoBox } from "./InfoBox";
import { DemandeStep } from "./DemandeStep";
import { DemandeVerification } from "./DemandeVerification";
import { countryCodes } from "./CountryCode/CountryCodes";

import { Cart } from "./Cart";
import { Payment } from "./Payment";
import { CardSection } from "./stripe/CardSection";
import { InjectedCheckoutForm as CheckoutForm } from "./stripe/CheckoutForm";
import { Modal } from "./Modal";
import { ModalCustom } from "./ModalCustom";
import { DemandeStepOptionItem } from "./DemandeStepOptionItem";
import { QuestionnairesForms } from "./forms/questionnaires";
import { QuestionnaireSteps } from "./QuestionnaireSteps";
import { AjoutDocumentStep } from "./AjoutDocumentStep";
import { QuestionnaireCiSteps } from "./forms/questionnaires/ci/QuestionnaireCiSteps";
import { QuestionnaireSnSteps } from "./forms/questionnaires/sn/QuestionnaireSnSteps";
import { QuestionnaireTgSteps } from "./forms/questionnaires/tg/QuestionnaireTgSteps";
import { QuestionnaireGnSteps } from "./forms/questionnaires/gn/QuestionnaireGnSteps";
import { QuestionnaireCmSteps } from "./forms/questionnaires/cm/QuestionnaireCmSteps";
import { QuestionnaireMaSteps } from "./forms/questionnaires/ma/QuestionnaireMaSteps";
import { QuestionnaireBnSteps } from "./forms/questionnaires/bn/QuestionnaireBnSteps";
import { QuestionnaireBfSteps } from "./forms/questionnaires/bf/QuestionnaireBfSteps";
import { QuestionnaireRdcSteps } from "./forms/questionnaires/rdc/QuestionnaireRdcSteps";
import { RedactionDocumentStep } from "./RedactionDocumentStep";
import { ObservationForm } from "./forms/ObservationForm";
import { SignatureDocumentStep } from "./SignatureDocumentStep";
import { EnregistrementCepiciStep } from "./EnregistrementCepiciStep";
import { LivraisonRccmStep } from "./LivraisonRccmStep";
import { FormalitesImmatricualationStep } from "./FormalitesImmatriculationStep";
import { RattachementFiscaleStep } from "./RattachementFiscaleStep";
import { DocumentList } from "./DocumentList";
import { PieceItem } from "./PieceItem";
import { LivraisonDfeStep } from "./LivraisonDfe";
import { LivraisonStep } from "./LivraisonStep";
import { FormalitesStep } from "./FormalitesStep";
import { ProgressBar } from "./ProgressBar";
import { AuthentificaitonNotaireStep } from "./AuthentificationNotaire";
import { VerificationDisponibiliteNomStep } from "./VerificationDisponibiliteNomStep";
import { NewPasswordForm } from "./NewPasswordForm";
import { ArticleItem } from "./ArticleItem";
import { MailForm } from "./forms/MailForm";
import { OptionalStep } from "./OptionalStep";
import { RecapStepV2 } from "./forms/questionnaires/RecapStepv2";
import { RecaptItem } from "./forms/questionnaires/RecapItem";
import { DocumentFormaliteStep } from "./DocumentFormaliteStep";
import { RecapEditForm } from "./RecapEditForm";
import { AssociesEdit } from "./forms/questionnaires/edit/AssociesEdit";
import { GerantsEdit } from "./forms/questionnaires/edit/GerantsEdit";
import { AdresseEdit } from "./forms/questionnaires/edit/AdressEdit";
import { InfoEdit } from "./forms/questionnaires/edit/InfoEdit";
import {RecapEditFormQuestionnaire} from "./RecapEditFormQuestionnaire";
export const Components = {
  CategorieUserForm,
  UserForm,
  RoleForm,
  PermissionForm,
  DossierForm,
  PaysForm,
  EtatDemandeForm,
  EtapeTraitementForm,
  TypePaiementForm,
  MoyenPaiementForm,
  DemandeForm,
  CodePromoForm,
  PaiementForm,
  TypeDocumentForm,
  EtatDocumentForm,
  DocumentForm,
  TypePieceForm,
  PieceForm,
  MobileMenu,
  countryCodes,
  SideNav,
  TopBar,
  LoginForm,
  ErrorMessages,
  RegisterForm,
  ObservationForm,
  AnalyticsCard,
  BreadCrump,
  Table,
  Dropdown,
  DropdownBox,
  DropdownItem,
  NewDemandeDropdownButton,
  InfoItem,
  InfoBox,
  DemandeStep,
  DemandeVerification,
  Cart,
  Payment,
  PasswordForm,
  Stripe: {
    CardSection,
    CheckoutForm,
  },
  Modal,
  ModalCustom,
  DemandeStepOptionItem,
  QuestionnairesForms,
  QuestionnaireSteps,
  AjoutDocumentStep,
  DocumentFormaliteStep,
  QuestionnaireCiSteps,
  QuestionnaireSnSteps,
  QuestionnaireGnSteps,
  QuestionnaireTgSteps,
  QuestionnaireCmSteps,
  QuestionnaireMaSteps,
  QuestionnaireBnSteps,
  QuestionnaireBfSteps,
  QuestionnaireRdcSteps,
  RedactionDocumentStep,
  SignatureDocumentStep,
  EnregistrementCepiciStep,
  FormalitesImmatricualationStep,
  VerificationDisponibiliteNomStep,
  LivraisonRccmStep,
  RattachementFiscaleStep,
  LivraisonDfeStep,
  LivraisonStep,
  FormalitesStep,
  AuthentificaitonNotaireStep,
  DocumentList,
  PieceItem,
  ProgressBar,
  NewPasswordForm,
  ArticleItem,
  MailForm,
  OptionalStep,
  RecapStepV2,
  RecaptItem,
  RecapEditForm,
  RecapEditFormQuestionnaire,
  AssociesEdit,
  GerantsEdit,
  AdresseEdit,
  InfoEdit,
};
