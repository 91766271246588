import styled from "styled-components";
import ContentLoader from "react-content-loader";
export const StyledError = styled.div`
  color: red;
  padding-bottom: 10px;
  margin-top: 10px;
`;

export const FormGroupedSurround = styled.div`
  display:flex;
  justify-content:center;
  margin: 0px 5px;
  align-items: baseline;
  flex-wrap: wrap;
  flex: 1;
`;

export const FormGroup = styled.div.attrs((props) => props)`
  margin: 0.8rem 5px;
  flex:0 0  ${props => (props?.flexWidth ? props.flexWidth+'%' : '48%')};
`;

export const FormGroupLarge = styled.div`
  margin: 1rem 5px;
  flex: 0 0 100%;
`;
export const FormLabel = styled.label`
  font-family: 'Lato', sans-serif;
  display:inline-block;
  max-width: 100%;
  width: inherit;
  color: #232b2b;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 8px;
  .radio-label-inner{
    font-size: 18px!important;
    margin-left: 0.5rem;
    //color: #006699;
  //  font-size: 1.0625rem;
    line-height: 1.5rem;
    letter-spacing: -0.025em;
    font-weight: 500;
    padding: 0px;
  }
`;


export const Select = styled.select`
  width: 100%;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #EEE;
  font-size: 15px;
  font-weight:400;
  color: #0D1136;
  line-height: 19px;
  padding: 0 18px;
  box-sizing: border-box;
  transition: border-color 0.25s ease;
  margin-bottom: 10px;

  &:hover,
  &:focus {
    outline: 0;
  }

  &:focus {
    border-color: #069;
  }

  &::placeholder {
    color: #77798c;
    font-size: 14px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled {
    .inner-wrap {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;


export const InputLoader = props => {
    return (
        <ContentLoader
            height={'48px'}
            width={'100%'}
            margin={0}
            speed={2}
            {...props}
        >

            <rect rx="10" ry="10"  width={'100%'} height={"100%"} />
        </ContentLoader>
    );
};
