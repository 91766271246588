import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminsSelector } from '../../../store/admins/selectors';
import { rolesSelector } from '../../../store/roles/selectors';
import { paysSelector } from '../../../store/pays/selectors';
import Button from "../../../components/Button/Button";
import { AdminFormWrapper } from "../Admins.style";
import { hideModal } from "../../../store/modal/actions";
import { useNavigate } from "react-router-dom";

function AdminsDetail() {
    const dispatch = useDispatch();
    const useAdmins = adminsSelector(useSelector(state => state));
    const useRoles = rolesSelector(useSelector(state => state));
    const usePays = paysSelector(useSelector(state => state));
    const navigate = useNavigate();

    const adminDetails = useAdmins?.admin_set_data;
    let lastSpaceIndex = useAdmins.admin_set_data?.username.lastIndexOf(" ");

    let firstSpace = useAdmins.admin_set_data?.username.indexOf(" ");
    let lastname = useAdmins?.admin_set_data?.username.substring(lastSpaceIndex + 1);
    let firstname = useAdmins.admin_set_data?.username.substring(0, lastSpaceIndex);

    useEffect(() => {
        // Optional: Add any side effects here
    }, [dispatch]);

    const handleEdit = () => {
        navigate('edition');
    };

    const getRoleLabelById = (roleId) => {
        if (useRoles.isLoading || !useRoles.data) return 'Chargement...';
        const role = useRoles.data.find(role => role.id === roleId);
        return role ? role.libelle : 'Rôle inconnu';
    };

    const getCountryLabelById = (countryId) => {
        if (usePays.pays_list_loading || !usePays.data) return 'Chargement...';
        const country = usePays.data.find(pays => pays.id === countryId);
        return country ? country.libelle : 'Pays inconnu';
    };

    return (
        <AdminFormWrapper className="p-6 bg-white rounded-lg shadow-lg">
            <div className="form-header mb-4">
                <h3 className="form-header-title text-xl font-semibold">Détails de l'utilisateur</h3>
            </div>
            <div className="admin-detail-content space-y-3">
                <div className="admin-detail-item flex justify-between">
                    <strong>Nom:</strong>
                    <span>{lastname}</span>
                </div>
                <div className="admin-detail-item flex justify-between">
                    <strong>Prénom:</strong>
                    <span>{firstname}</span>
                </div>
                <div className="admin-detail-item flex justify-between">
                    <strong>Email:</strong>
                    <span>{adminDetails?.email}</span>
                </div>
                <div className="admin-detail-item flex justify-between">
                    <strong>Numéro de téléphone:</strong>
                    <span>{adminDetails?.numero_telephone}</span>
                </div>
                <div className="admin-detail-item flex justify-between">
                    <strong>Rôle:</strong>
                    <span>{getRoleLabelById(adminDetails?.role_id)}</span>

                </div>
                <div className="admin-detail-item flex justify-between">
                    <strong>Pays:</strong>
                    <span>{getCountryLabelById(adminDetails?.pays_id)}</span>

                </div>
            </div>
            <div className="admin-detail-buttons mt-6 flex justify-end">
                <Button
                    title="Éditer"
                    onClick={handleEdit}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition-colors"
                />
            </div>
        </AdminFormWrapper>
    );
}

export default AdminsDetail;
