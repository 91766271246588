import React, { useState } from "react";
import * as Icons from "react-feather";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const API_URL = "https://devapi.legafrik.com/api/admin/admin/password";

function EditerMotDePasse() {
    const [inputFields, setInputFields] = useState({
        password: "",
        password_confirmation: ""
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState("");

    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setInputFields({ ...inputFields, [name]: value });

        if (name === "password") {
            evaluatePasswordStrength(value);
        }
    };

    const evaluatePasswordStrength = (password) => {
        let strength = 0;

        if (password.length >= 6) strength++;
        if (password.length >= 8) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[a-z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[\W]/.test(password)) strength++;

        if (strength <= 2) {
            setPasswordStrength("Faible");
        } else if (strength <= 4) {
            setPasswordStrength("Moyen");
        } else {
            setPasswordStrength("Fort");
        }
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, password_confirmation } = inputFields;

        if (password !== password_confirmation) {
            toast.error("Les mots de passe ne correspondent pas.");
            return;
        }

        if (passwordStrength === "Faible") {
            toast.error("Le mot de passe est trop faible.");
            return;
        }

        try {
            const response = await axios.put(API_URL, {
                password,
                password_confirmation
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.success) {
                toast.success("Mot de passe mis à jour avec succès !");
                navigate("/");
            } else {
                toast.error("Erreur lors de la mise à jour du mot de passe.");
            }
        } catch (error) {
            toast.error("Erreur de communication avec le serveur.");
            console.error("Error updating password", error);
        }
    };

    return (
        <div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

            <h2 className="text-xl font-bold mb-4">Modifier le mot de passe</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4 relative">
                    <label>Nouveau mot de passe :</label>
                    <div className="relative">
                        <input
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Mot de passe"
                            value={inputFields.password}
                            onChange={handleFormChange}
                            className="input pr-12 w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            required
                        />
                        <div
                            onClick={togglePassword}
                            className="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 cursor-pointer"
                        >
                            {passwordShown ? <Icons.Eye /> : <Icons.EyeOff />}
                        </div>
                    </div>
                    <p className={`mt-2 text-sm ${passwordStrength === 'Faible' ? 'text-red-500' : passwordStrength === 'Moyen' ? 'text-yellow-500' : 'text-green-500'}`}>
                        Force du mot de passe : {passwordStrength}
                    </p>
                </div>

                <div className="mb-4 relative">
                    <label>Confirmer le nouveau mot de passe :</label>
                    <div className="relative">
                        <input
                            type={passwordShown2 ? "text" : "password"}
                            className="input pr-12 w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            placeholder="Confirmer mot de passe"
                            id="confirmPassword"
                            name="password_confirmation"
                            value={inputFields.password_confirmation}
                            onChange={handleFormChange}
                            required
                        />
                        <div
                            onClick={togglePassword2}
                            className="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 cursor-pointer"
                        >
                            {passwordShown2 ? <Icons.Eye /> : <Icons.EyeOff />}
                        </div>
                    </div>
                </div>

                <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-lg">
                    Mettre à jour
                </button>
            </form>
        </div>
    );
}

export default EditerMotDePasse;
