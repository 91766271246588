export const ROLES_LIST_REQUESTING = 'roles/ROLES_LIST_REQUESTING';
export const ROLES_LIST_SUCCESS = 'roles/ROLES_LIST_SUCCESS';
export const ROLES_LIST_FAILED = 'roles/ROLES_LIST_FAILED';
export const UPDATE_ROLE_REQUEST = 'roles/UPDATE_ROLE_REQUEST';
export const CREATE_ROLE_REQUEST = 'roles/CREATE_ROLE_REQUEST ';
export const UPDATE_ROLE_SUCCESS = 'roles/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'roles/UPDATE_ROLE_FAILED';
export const CREATE_ROLE_SUCCESS = 'roles/CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILED = 'roles/CREATE_ROLE_FAILED';


