import {combineReducers} from "redux";
import modalReducer from "./modal/reducer";
import authReducer from "./auth/reducer";
import adminsReducer from "./admins/reducer";
import rolesReducer from "./roles/reducer";
import demandsReducer from "./demands/reducer";
import statusDemandsReducer from "./status-demands/reducer";
import typeDemandsReducer from "./type-demands/reducer";
import clientsReducer from "./clients/reducer";
import paymentMethodsReducer from "./payment-methods/reducer";
import paymentsReducer from "./payments/reducer";
import typeDocumentsReducer from "./type-documents/reducer";
import etatDocumentsReducer from "./etat-documents/reducer";
import etatEtapeTraitementsReducer from "./etat-etape-traitements/reducer";
import etapeTraitementsReducer from "./etape-traitements/reducer";
import statusEtatTraitementsReducer from "./status-etat-traitements/reducer";
import paysReducer from "./pays/reducer";
import articlesReducer from "./articles/reducer";
import categoriesReducer from "./categories/reducer";
import typePiecesReducer from "./type-pieces/reducer";

export const globalReducers = combineReducers({
    modal:modalReducer,
    auth:authReducer,
    admins:adminsReducer,
    roles:rolesReducer,
    demands:demandsReducer,
    statusDemands:statusDemandsReducer,
    typeDemands:typeDemandsReducer,
    clients:clientsReducer,
    paymentMethods:paymentMethodsReducer,
    payments:paymentsReducer,
    typeDocuments:typeDocumentsReducer,
    etatDocuments:etatDocumentsReducer,
    etatEtapeTraitements:etatEtapeTraitementsReducer,
    etapeTraitements:etapeTraitementsReducer,
    statusEtatTraitements:statusEtatTraitementsReducer,
    pays:paysReducer,
    articles:articlesReducer,
    categories:categoriesReducer,
    typePieces:typePiecesReducer
})
