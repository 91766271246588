import React from "react";

function LogListe() {
  return (
    <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <div
        className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center my-2
                justify-between"
      >
        <div className="p-5 text-xl  font-medium ">Les Logs </div>
      </div>
      <table className="table table-report mt-6">
        <thead>
          <tr className="">
            <th>Actions</th>
            <th>Utilisateurs</th>
            <th>Role</th>
            <th className="">Organisation</th>
            <th className="">Date</th>

            <th className="">Heure</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Creation Utilisateurs</td>
            <td>Kofi Réné</td>
            <td>Superadmin</td>
            <td>La poste</td>
            <td>12/09/2024</td>
            <td>14:38</td>
          </tr>
           <tr>
            <td>Demande retrait comission</td>
            <td>Yao Jean</td>
            <td>Master</td>
            <td>La poste</td>
            <td>12/09/2022</td>
            <td>14:38</td>
          </tr>
           <tr>
            <td>Creation Utilisateurs</td>
            <td>Kofi Réné</td>
            <td>Superadmin</td>
            <td>La poste</td>
            <td>12/09/2024</td>
            <td>14:38</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LogListe;
