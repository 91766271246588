import { useCallback, useEffect, useState } from "react";

export function Cart(props) {
    const {selectedProductList, productList} = props;

    const [dynamicProductList, setDynamicProductList] = useState([]);
    const [netAmount, setNetAmount] = useState("");

    const getTaxAmount = currency => {
        if (currency === "MAD") return 20
        return 0;
    }

    const initalize = useCallback(() => {
        const pricedProductList = [];

        selectedProductList.forEach(selectedProduct => {
            productList.forEach(product => {
                const key = Object.keys(selectedProduct)[0];
                
                if (key !== product.name) return;

                const option = product.options.find(
                    option => option.value === selectedProduct[key]);
                
                if (!option || !option.price) return
                    
                pricedProductList.push({
                    name: product.name,
                    value: option.value,
                    price: option.price
                });
            })
        });

        const amount = pricedProductList.reduce((car, value) => 
        car += value.price, 0);
        const taxRate = (getTaxAmount(props.currency) / 100) + 1;
        
        setDynamicProductList([...pricedProductList]);
        setNetAmount(amount);
        props.setAmount(Math.round(amount * taxRate));
    }, [selectedProductList, productList])

    useEffect(() => {
        initalize()
    }, [initalize])

    return (
        <>
            <div className="px-5 mt-10">
                <div className="font-medium text-center text-lg">
                    Résumé de votre commande
                </div>
            </div>
            <div className="pos intro-y grid grid-cols-12 gap-5 mt-5 px-5">
                <div className="col-span-12 lg:col-span-8">
                    <div className="tab-content">
                        <div className="tab-content__pane active" id="ticket">
                            {dynamicProductList.map((product, index) => {
                                return (
                                <div className="pos__ticket box p-2 mt-5" key={index}>
                                    <div className="flex items-center p-3 transition duration-300 
                                    ease-in-out bg-white dark:bg-dark-3 hover:bg-gray-200 
                                    dark:hover:bg-dark-1 rounded-md">
                                        <div className="pos__ticket__item-name truncate mr-1">
                                            {product.value}
                                        </div>
                                        <div className="ml-auto">
                                            {product.price} {props.currency ?? "FCFA"}
                                        </div>
                                    </div>
                                </div>
                                )
                            } )}
                        </div>
                    </div>
                </div>
                <div className="col-span-12 lg:col-span-4">
                    <div className="box p-5 mt-5">
                        <div className="flex">
                            <div className="mr-auto">Sous-total</div>
                            <div>{netAmount} {props.currency ?? "FCFA"}</div>
                        </div>
                        <div className="flex mt-2">
                            <div className="mr-auto">TVA</div>
                            <div>{getTaxAmount(props.currency)}%</div>
                        </div>
                        <div className="flex mt-4 pt-4 border-t border-gray-200 dark:border-dark-5">
                            <div className="mr-auto font-medium text-base">Total</div>
                            <div className="font-medium text-base">
                                {props.amount} {props.currency ?? "FCFA"}
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-5">
                        <button className="button w-32 border border-gray-400 dark:border-dark-5 
                        text-gray-600 dark:text-gray-300" onClick={props.handleBackClick}>
                            Revenir
                        </button>
                        <button className="button w-32 text-white bg-theme-1 shadow-md ml-auto"
                        onClick={props.handleValidateClick}>
                            {props.isDisabled ? "Chargements..." : "Valider"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
    
}