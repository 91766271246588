import { put, call, takeEvery } from 'redux-saga/effects';
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import * as ActionsModal from "../modal/actions";

const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listAdminsSaga(loginPayload) {
    const { payload } = loginPayload
    let url = `admin/admins?page=${payload?.page}`

    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.adminsListSuccess(response?.data));
    } catch (error) {
        yield put(constants.adminsListFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.")
    }

}

function* AdminsAddSaga(loginPayload) {
    const { payload } = loginPayload
    const url = 'admin/admins'

    try {
        const response = yield call(_adminsService.post, url, payload);
        yield put(constants.adminsAddSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success, "L'utilisateur a été ajouté avec succès")
    } catch (error) {
        yield put(constants.adminsAddFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.")
    }
}

function* AdminsEditSaga(loginPayload) {
    const { payload } = loginPayload
    const url = `admin/admins/${payload.id}`
    const send_data = {
        username: payload.username,
        email: payload?.email,
        role_id: payload?.role_id,
        profile_url: "",
        pays_id: payload?.pays_id,
        numero_telephone: payload?.numero_telephone
    }

    try {
        const response = yield call(_adminsService.put, url, send_data);
        yield put(constants.adminsEditSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success, "L'utilisateur a été modifié avec succès")
    } catch (error) {
        yield put(constants.adminsEditFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.")
    }
}

function* listCouriersSaga(loginPayload) {
    //const {payload}=loginPayload
    const url = 'admin/admins'
    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.courierListSuccess(response?.data?.admins));
    } catch (error) {
        yield put(constants.courierListFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.")
    }

}

function* listAdminsPaysSaga(loginPayload) {
    const { payload } = loginPayload

    let url = `admin/admins`

    if (payload?.pays_id !== '' && payload?.pays_id !== null) {
        url += `?pays_id=${payload?.pays_id}`
    }

    if (payload?.page !== '' && payload?.page !== undefined && payload?.page !== null) {
        url += (payload?.pays_id !== '' && payload?.pays_id !== null) ? `&page=${payload?.page}` : `?page=${payload?.page}`
    }

    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.adminsPaysListSuccess(response?.data));
    } catch (error) {
        yield put(constants.adminsPaysListFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.")
    }
}

export default function* adminsSaga() {
    yield takeEvery(Actions.ADMINS_LIST_REQUESTING, listAdminsSaga);
    yield takeEvery(Actions.ADMINS_PAYS_LIST_REQUESTING, listAdminsPaysSaga);
    yield takeEvery(Actions.ADMINS_ADD_REQUESTING, AdminsAddSaga);
    yield takeEvery(Actions.ADMINS_EDIT_REQUESTING, AdminsEditSaga);
    yield takeEvery(Actions.COURIERS_LIST_REQUESTING, listCouriersSaga);
}
