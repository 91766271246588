import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import { rolesListRequest, updateRoleRequest } from "../../../store/roles/actions";
import { rolesSelector } from "../../../store/roles/selectors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { FormGroup, FormLabel, StyledError } from "../../../components/StyledErro";
import { AdminFormWrapper } from "../../Admins/Admins.style";
import { FormModal } from "../../../components/FormModal/FormModal";
import { hideModal } from "../../../store/modal/actions";
import { useNavigate } from "react-router-dom";  // Importation du hook useNavigate


// Liste des permissions
const ALL_PERMISSIONS = [
  'create-admin', 'create-categorieuser', 'create-codepromo', 'create-demande', 'create-document',
  'create-dossier', 'create-etapetraitement', 'create-etatdemande', 'create-etatdocument',
  'create-moyenpaiement', 'create-paiement', 'create-pays', 'create-permission', 'create-piece',
  'create-role', 'create-typedemande', 'create-typedocument', 'create-typepaiement', 'create-typepiece',
  'create-user', 'create-message', 'create-etatetapetraitement', 'create-statusetattraitement',
  'create-observation', 'delete-admin', 'delete-categorieuser', 'delete-codepromo', 'delete-demande',
  'delete-document', 'delete-dossier', 'delete-etapetraitement', 'delete-etatdemande', 'delete-etatdocument',
  'delete-moyenpaiement', 'delete-paiement', 'delete-pays', 'delete-permission', 'delete-piece',
  'delete-role', 'delete-typedemande', 'delete-typedocument', 'delete-typepaiement', 'delete-typepiece',
  'delete-user', 'delete-message', 'delete-etatetapetraitement', 'delete-statusetattraitement',
  'delete-observation', 'update-admin', 'update-categorieuser', 'update-codepromo', 'update-demande',
  'update-document', 'update-dossier', 'update-etapetraitement', 'update-etatdemande', 'update-etatdocument',
  'update-moyenpaiement', 'update-paiement', 'update-pays', 'update-permission', 'update-piece',
  'update-role', 'update-typedemande', 'update-typedocument', 'update-typepaiement', 'update-typepiece',
  'update-user', 'update-message', 'update-etatetapetraitement', 'update-statusetattraitement',
  'update-observation', 'view-any-admin', 'view-any-categorieuser', 'view-any-codepromo', 'view-any-demande',
  'view-any-document', 'view-any-dossier', 'view-any-etapetraitement', 'view-any-etatdemande',
  'view-any-etatdocument', 'view-any-moyenpaiement', 'view-any-paiement', 'view-any-pays', 'view-any-permission',
  'view-any-piece', 'view-any-role', 'view-any-typedemande', 'view-any-typedocument', 'view-any-typepaiement',
  'view-any-typepiece', 'view-any-user', 'view-any-message', 'view-any-etatetapetraitement',
  'view-any-statusetattraitement', 'view-any-observation', 'create-attribution', 'view-any-attribution',
  'update-attribution', 'delete-attribution'
];

// Validation Schema
const RoleEditValidationSchema = Yup.object().shape({
  libelle: Yup.string().required("Le nom du rôle est requis"),
  permissions: Yup.array().min(1, "Au moins une permission est requise"),
});

// Fonction pour catégoriser les permissions
const categorizePermissions = (permissions) => {
  const categorized = {
    creation: [],
    suppression: [],
    modification: [],
    visualisation: [],
  };

  permissions.forEach((permission) => {
    if (permission.startsWith("create-")) {
      categorized.creation.push(permission);
    } else if (permission.startsWith("delete-")) {
      categorized.suppression.push(permission);
    } else if (permission.startsWith("update-")) {
      categorized.modification.push(permission);
    } else if (permission.startsWith("view-any-")) {
      categorized.visualisation.push(permission);
    }
  });

  return categorized;
};

const RolesEditForm = () => {
  const navigate = useNavigate();  // Initialisation de useNavigate

  const { id } = useParams();
  const dispatch = useDispatch();
  const useRoles = rolesSelector(useSelector((state) => state));
  const [roleDetails, setRoleDetails] = useState({ libelle: "", permissions: [] });
  const [openDropdown, setOpenDropdown] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Ajout du state isSubmitting


  useEffect(() => {
    if (useRoles?.data && useRoles.data.length > 0) {
      const role = useRoles.data.find((r) => r.id === parseInt(id));
      if (role) {
        setRoleDetails({ libelle: role.libelle, permissions: role.permissions });
      }
    } else {
      dispatch(rolesListRequest());
    }
  }, [dispatch, id, useRoles]);


  const initialValues = {
    libelle: roleDetails.libelle || "",
    permissions: roleDetails.permissions || [],
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true); // Activer l'état d'envoi

    try {
      await dispatch(updateRoleRequest(id, values));
      toast.success("Rôle mis à jour avec succès!");
      navigate("/roles-list");
    } catch (error) {
      toast.error("Échec de la mise à jour du rôle.");
    } finally {
      setIsSubmitting(false); // Désactiver l'état d'envoi
    }
  };
  // Créer la liste des permissions
  const permissionsList = ALL_PERMISSIONS.map((permission) => ({
    value: permission,
    label: permission.charAt(0).toUpperCase() + permission.slice(1).replace(/-/g, ' '),
    isChecked: roleDetails.permissions.includes(permission), // Cocher les permissions associées
  }));

  // Catégoriser les permissions
  const categorizedPermissions = categorizePermissions(ALL_PERMISSIONS);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={RoleEditValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form className="legafrik-form-parent">
          <FormGroup>
            <FormLabel htmlFor="libelle">Nom du rôle :</FormLabel>
            <Field name="libelle">
              {({ field }) => (
                <Input
                  {...field}
                  className="input-format"
                  id="libelle"
                  name="libelle"
                  type="text"
                  placeholder="Nom du rôle"
                />
              )}
            </Field>
            <ErrorMessage name="libelle" component={StyledError} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Permissions :</FormLabel>
            <div className="flex flex-wrap justify-between mb-4">
              {Object.entries(categorizedPermissions).map(([category, permissions]) => (
                <div key={category} className="w-1/2 pr-2">
                  <button
                    type="button"
                    onClick={() => setOpenDropdown((prev) => ({ ...prev, [category]: !prev[category] }))}
                    className="bg-theme-1 text-white w-full text-left p-2 rounded-md mb-2"
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)} {openDropdown[category] ? '▲' : '▼'}
                  </button>
                  {openDropdown[category] && (
                    <div className="permissions-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', paddingLeft: '20px' }}>
                      {permissions.map((permission) => {
                        const permissionDetails = permissionsList.find(p => p.value === permission);
                        return (
                          <div key={permission} style={{ display: 'inline-block', marginRight: '10px' }}>
                            <Field
                              type="checkbox"
                              name="permissions"
                              value={permission}
                              checked={values.permissions.includes(permission)}
                              onChange={() => {
                                const updatedPermissions = values.permissions.includes(permission)
                                  ? values.permissions.filter((perm) => perm !== permission)
                                  : [...values.permissions, permission];

                                setFieldValue("permissions", updatedPermissions);
                              }}
                            />
                            <label htmlFor={permission} style={{ marginLeft: '5px' }}>
                              {permissionDetails.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <ErrorMessage name="permissions" component={StyledError} />
          </FormGroup>

          <div className="legafrik-form-btn-box">
            <Button
              type="submit"
              title="Mettre à jour le rôle"
              className="bg-theme-1 text-white"
              disabled={isSubmitting} // Désactivation du bouton pendant la soumission via useState
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const RolesEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const useModal = useSelector((state) => state.modal);
  const useRoles = useSelector((state) => state.roles);

  const _handleConfirmed = () => {
    const sendData = {
      id,
      libelle: useModal?.data?.libelle,
      permissions: useModal?.data?.permissions,
    };
    dispatch(updateRoleRequest(sendData));
  };

  useEffect(() => {
    if (useRoles?.data?.length === 0) {
      dispatch(rolesListRequest());
    }
  }, [dispatch, useRoles]);

  return (
    <AdminFormWrapper>
      <div className="form-header">
        <h3 className="form-header-title">Modification d'un rôle</h3>
      </div>
      <RolesEditForm />
      {useModal.isOpenConfirmedArticleAdd && (
        <FormModal isControlVisible={true} handleModalClose={() => dispatch(hideModal())}>
          <div className="legafrik-confirm-body">
            <div className="legafrik-confirm-body-content">
              <div className="legafrik-confirm-body-item">
                <span className="legafrik-confirm-body-item-title">Nom du rôle :</span>
                <span className="legafrik-confirm-body-item-info">{useModal?.data?.libelle}</span>
              </div>
              <div className="legafrik-confirm-body-item">
                <span className="legafrik-confirm-body-item-title">Permissions :</span>
                <span className="legafrik-confirm-body-item-info">
                  {useModal?.data?.permissions.join(", ")}
                </span>
              </div>
            </div>
          </div>

          <div className="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5">
            <button
              type="button"
              className="button border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
              onClick={() => dispatch(hideModal())}
            >
              Fermer
            </button>
            <button
              onClick={_handleConfirmed}
              className="button bg-theme-1 text-white"
              disabled={useRoles.roles_update_loading}
            >
              {useRoles.roles_update_loading ? "Envoi en cours ..." : "Confirmer"}
            </button>
          </div>
        </FormModal>
      )}
      <ToastContainer />
    </AdminFormWrapper>
  );
};

export default RolesEdit;
