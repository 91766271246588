import ApiService from "./api.service";

const apiUrl = new ApiService();
class AdminsService {
  /**
   *
   * @param url
   * @returns {Promise<T>}
   */
  geList(url) {
    return apiUrl
      ._get(url)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  post(url, data) {
    return apiUrl
      ._post(url, data)
      .then((response) => {
        console.log(response.data);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  postOffline(url, data) {
    return apiUrl
      ._postOffline(url, data)
      .then((response) => {
        console.log(response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  get(url) {
    return apiUrl
      ._get(url)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  put(url, data) {
    return apiUrl
      ._put(url, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  delete(url) {
    return apiUrl
      ._delete(url)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}

export default AdminsService;
