
import { useEffect, useState } from "react";
import { Utils } from "../../../utils";
import moment from "moment/moment";

export function GerantStep(props) {
    const formContainer = "gerants-form-container";
    const associes = JSON.parse(props.associes);
    const [nombreGerant, setNombreGerant] = useState(props?.gerants?.length); // Default to at least one gerant
    const [gerants, setGerants] = useState(props?.gerants); // Default to empty array for gerants

    const handleInputChange = (index, name, value) => {
        const gerantsCopy = [...gerants]; // Créer une copie de l'état actuel des gérants

        if (name === "associe-gerant") {
            const associeSelected = JSON.parse(value); // Convertir la valeur sélectionnée en objet
            // On ne met à jour les informations de l'associé que si "associe-gerant" est "oui"
            if (gerantsCopy[index]["associe-gerant"] === "oui") {
                gerantsCopy[index] = {
                    ...gerantsCopy[index], // Conserver les autres champs existants
                    // Mettre à jour uniquement les champs pertinents pour un associé
                    adresse: associeSelected.adresse || "",
                    'adresse-mail': associeSelected['adresse-mail'] || "",
                    civilite: associeSelected.civilite || "",
                    'date-fin-validite-piece-identite': associeSelected['date-fin-validite-piece-identite'] || moment().format('YYYY-MM-DD'),
                    'date-naissance': associeSelected['date-naissance'] || moment().format('YYYY-MM-DD'),
                    'date-piece-identite': associeSelected['date-piece-identite'] || moment().format('YYYY-MM-DD'),
                    'lieu-naissance': associeSelected['lieu-naissance'] || "",
                    nationalite: associeSelected.nationalite || "",
                    nom: associeSelected.nom || "",
                    'nom-mere': associeSelected['nom-mere'] || "",
                    'nom-pere': associeSelected['nom-pere'] || "",
                    'numero-piece-identite': associeSelected['numero-piece-identite'] || "",
                    'numero-telephone': associeSelected['numero-telephone'] || "",
                    'pays-naissance': associeSelected['pays-naissance'] || "",
                    'pays-piece-identite': associeSelected['pays-piece-identite'] || "",
                    prenoms: associeSelected.prenoms || "",
                    'regime-matrimonial': associeSelected['regime-matrimonial'] || "",
                    'situation-matrimoniale': associeSelected['situation-matrimoniale'] || "",
                    type: associeSelected.type || "particulier",
                    'type-piece-identite': associeSelected['type-piece-identite'] || ""
                };
            }
        } else {
            // Si c'est un autre champ, on met simplement à jour le champ concerné
            gerantsCopy[index] = {
                ...gerantsCopy[index], // Conserver les autres champs
                [name]: value, // Mettre à jour le champ spécifié
                type: 'particulier' // S'assurer que le type est défini
            };
        }

        setGerants(gerantsCopy); // Mettre à jour l'état des gérants
    };



    const handleTypeGerantChange = (index, value) => {
        const gerantsCopy = [...gerants];

        // Réinitialiser les informations lorsque l'option change
        if (value === "oui") {
            gerantsCopy[index] = {
                ...gerantsCopy[index], // Conserver les autres champs existants
                "associe-gerant": value, // Mettre à jour seulement "associe-gerant"
                adresse: "", // Réinitialiser les champs à vide
                'adresse-mail': "",
                civilite: "",
                'date-fin-validite-piece-identite': moment().format('YYYY-MM-DD'),
                'date-naissance': moment().format('YYYY-MM-DD'),
                'date-piece-identite': moment().format('YYYY-MM-DD'),
                'lieu-naissance': "",
                nationalite: "",
                nom: "",
                'nom-mere': "",
                'nom-pere': "",
                'numero-piece-identite': "",
                'numero-telephone': "",
                'pays-naissance': "",
                'pays-piece-identite': "",
                prenoms: "",
                'regime-matrimonial': "",
                'situation-matrimoniale': "",
                type: "particulier",
                'type-piece-identite': ""
            };
        } else if (value === "non") {
            gerantsCopy[index] = {
                ...gerantsCopy[index], // Conserver les autres champs existants
                "associe-gerant": value, // Mettre à jour seulement "associe-gerant"
                // Vider tous les autres champs du gérant
                adresse: "",
                'adresse-mail': "",
                civilite: "",
                'date-fin-validite-piece-identite': "",
                'date-naissance': "",
                'date-piece-identite': "",
                'lieu-naissance': "",
                nationalite: "",
                nom: "",
                'nom-mere': "",
                'nom-pere': "",
                'numero-piece-identite': "",
                'numero-telephone': "",
                'pays-naissance': "",
                'pays-piece-identite': "",
                prenoms: "",
                'regime-matrimonial': "",
                'situation-matrimoniale': "",
                type: "particulier",
                'type-piece-identite': ""
            };
        }

        setGerants(gerantsCopy); // Mettre à jour l'état des gérants
    };


    const handleStepSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < gerants.length; index++) {
            let gerant = gerants[index];
            gerant['date-piece-identite'] = gerant['date-piece-identite'] || moment().format('YYYY-MM-DD');
            gerant['date-fin-validite-piece-identite'] = gerant['date-fin-validite-piece-identite'] || moment().format('YYYY-MM-DD');
            gerant['date-naissance'] = gerant['date-naissance'] || moment().format('YYYY-MM-DD');
        }

        if (!nombreGerant || nombreGerant <= 0) {
            return alert("Vous devez remplir le formulaire !");
        }

        props.handleNextClick(e, 'gerants', JSON.stringify(gerants));
    }

    useEffect(() => {
        if (nombreGerant > gerants.length) {
            setGerants(prevGerants => [...prevGerants, ...Array(nombreGerant - prevGerants.length).fill({})]);
        } else if (nombreGerant < gerants.length) {
            setGerants(prevGerants => prevGerants.slice(0, nombreGerant));
        }
    }, [nombreGerant]);

    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12 text-lg leading-none">
            </h4>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Combien de gérants voulez-vous nommer ? </div>
                <input type="number" className="input w-full border flex-1" value={nombreGerant}
                    min={1} max={4} placeholder="Combien de gérants voulez-vous nommer ?"
                    onChange={e => setNombreGerant(e.target.value)} />
            </div>
            {gerants.map((gerant, index) => {
                return (
                    <div className="intro-y col-span-12" key={index} id={`${formContainer}-${index}`}>
                        <small>Indiquez les informations relatives au gérant {index + 1}</small>
                        <div className="mb-2">Le gérant {index + 1} fait partie des Associés ?</div>
                        <div className="flex flex-col sm:flex-row mt-4 mb-2">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                                <input type="radio" className="input border mr-2"
                                    id={`horizontal-radio-oui-${index}`}
                                    name={`gerant-${index}`} value="oui"
                                    checked={gerant['associe-gerant'] === 'oui'}
                                    onChange={e => handleTypeGerantChange(index, "oui")} />
                                <label className="cursor-pointer select-none"
                                    htmlFor={`horizontal-radio-oui-${index}`}>
                                    Oui
                                </label>
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                                <input type="radio" className="input border mr-2"
                                    id={`horizontal-non-${index}`}
                                    name={`gerant-${index}`} value="non"
                                    checked={gerant['associe-gerant'] === 'non'}
                                    onChange={e => handleTypeGerantChange(index, "non")} />
                                <label className="cursor-pointer select-none"
                                    htmlFor={`horizontal-non-${index}`}>
                                    Non
                                </label>
                            </div>
                        </div>
                        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${gerant['associe-gerant'] === 'oui' ? '' : 'hidden'}`}>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Indiquez l’associé gérant</div>
                                <select type="text" className="input w-full border flex-1 field"
                                    onChange={e => {
                                        //console.log("Selected associe:", e.target.value); // Vérifier la valeur sélectionnée
                                        handleInputChange(index, 'associe-gerant', e.target.value);
                                    }}
                                ><option value={JSON.stringify({ associe: null })}>
                                        {""} Sélectionner un Associé
                                    </option>
                                    {associes.map((associe, _index) => (
                                        <option value={JSON.stringify(associe)} key={_index}>
                                            {Utils.Demande.getAssocieName(associe)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom & Prénom(s) du père du gérant</div>
                                <input type="text" value={gerant['nom-pere']} className="input w-full border flex-1 field"
                                    placeholder="Nom & Prénom(s) du père du gérant" onChange={e => handleInputChange(
                                        index, 'nom-pere', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom & Prénom(s) de la mère du  gérant</div>
                                <input type="text" value={gerant['nom-mere']} className="input w-full border flex-1 field"
                                    placeholder="Nom & Prénom(s) de la mère du  gérant" onChange={e => handleInputChange(
                                        index, 'nom-mere', e.target.value)} />
                            </div>
                        </div>
                        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${(gerant['associe-gerant'] !== undefined &&
                                gerant['associe-gerant'] === 'non') ? '' : 'hidden'}`}>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">
                                    Numéro  de la carte d’identité
                                    <small>(les anciennes pièces ne sont pas prises en compte )</small>
                                </div>
                                <input type="text" value={gerant['numero-piece-identite']} className="input w-full border flex-1 field"
                                    placeholder="Numéro   de la carte d’identité" onChange={e => handleInputChange(
                                        index, 'numero-piece-identite', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Type de la piece d'identité</div>
                                <select type="text" value={gerant['type-piece-identite']} className="input w-full border flex-1 field"
                                    onChange={e => handleInputChange(index, 'type-piece-identite', e.target.value)}>
                                    <option value="CNI">CNI</option>
                                    <option value="Passeport">Passeport</option>
                                    <option value="Attestation d'identité">Attestation d'identité</option>
                                    <option value="Titre de séjour">Titre de séjour</option>
                                    <option value="Carte de résident">Carte de résident</option>
                                    <option value="Autres">Autres</option>
                                </select>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Pays de délivrance de la pièce</div>
                                <input type="text" value={gerant['pays-piece-identite']} className="input w-full border flex-1 field"
                                    placeholder="Pays de délivrance de la pièce" onChange={e => handleInputChange(
                                        index, 'pays-piece-identite', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Date d’établissement de la pièce</div>
                                <input type="date"
                                    value={gerant['date-piece-identite']} className="input w-full border flex-1 field"
                                    placeholder="Date d’établissement de la pièce" onChange={e => handleInputChange(
                                        index, 'date-piece-identite', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Date de fin de validité de la pièce</div>
                                <input
                                    type="date"
                                    className="input w-full border flex-1 field"
                                    value={gerant['date-fin-validite-piece-identite']}
                                    placeholder="Date de fin de validité de la pièce"
                                    onChange={e => handleInputChange(
                                        index, 'date-fin-validite-piece-identite', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Nationalite</div>
                                <input type="text" value={gerant['nationalite']} className="input w-full border flex-1 field"
                                    placeholder="Nationalite" onChange={e => handleInputChange(
                                        index, 'nationalite', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Adresse</div>
                                <input type="text" className="input w-full border flex-1 field"
                                    value={gerant['adresse']}
                                    placeholder="Adresse" onChange={e => handleInputChange(
                                        index, 'adresse', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Civilite</div>
                                <select value={gerant['civilite']} className="input w-full border flex-1 field"
                                    onChange={e => handleInputChange(index, 'civilite', e.target.value)}>
                                    <option value="monsieur">Monsieur</option>
                                    <option value="madame">Madame</option>
                                    <option value="mademoiselle">Mademoiselle</option>
                                </select>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom</div>
                                <input type="text" value={gerant['nom']} className="input w-full border flex-1 field"
                                    placeholder="Nom" onChange={e => handleInputChange(
                                        index, 'nom', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Prenom</div>
                                <input type="text" value={gerant['prenoms']} className="input w-full border flex-1 field"
                                    placeholder="Prenom" onChange={e => handleInputChange(
                                        index, 'prenoms', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Numéro de téléphone</div>
                                <input type="text" value={gerant['numero-telephone']} className="input w-full border flex-1 field"
                                    placeholder="Numéro de téléphone" onChange={e => handleInputChange(
                                        index, 'numero-telephone', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Date de naissance</div>
                                <input type="date" className="input w-full border flex-1 field"
                                    value={gerant['date-naissance']}
                                    placeholder="Date de naissance" onChange={e => handleInputChange(
                                        index, 'date-naissance', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Lieu de naissance</div>
                                <input type="text" value={gerant['lieu-naissance']} className="input w-full border flex-1 field"
                                    placeholder="Lieu de naissance" onChange={e => handleInputChange(
                                        index, 'lieu-naissance', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom & Prénom(s) du père du gérant</div>
                                <input type="text" value={gerant['nom-pere']} className="input w-full border flex-1 field"
                                    placeholder="Nom & Prénom(s) du père du gérant" onChange={e => handleInputChange(
                                        index, 'nom-pere', e.target.value)} />
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom & Prénom(s) de la mère du  gérant</div>
                                <input type="text" value={gerant['nom-mere']} className="input w-full border flex-1 field"
                                    placeholder="Nom & Prénom(s) de la mère du  gérant" onChange={e => handleInputChange(
                                        index, 'nom-mere', e.target.value)} />
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}
                    disabled={props.isDisabled}>
                    Revenir
                </button>
                <button className="button justify-center block bg-theme-1 
                text-white ml-2 px-6" onClick={handleStepSubmit} disabled={props.isDisabled}>
                    {props.isDisabled ? "Chargement en cours..." : "Suivant"}
                </button>
            </div>
        </div>
    );
}


