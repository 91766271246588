import styled from "styled-components";

export const AdminFormWrapper = styled.div.attrs((props) => props)`
  height: inherit;
  margin: 10px auto;
  width: ${props => (props?.flexWidth ? props?.flexWidth+'%' : '85%')};

  background: #fff;
  padding: 15px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  
  input,select{
    background-color:#EEEEEE;
  }
  .form-header{
    text-align: center;
    .form-header-title{
      font-size:20px;
      font-weight:bold;
    }
  }
  
  .legafrik-form-btn-box{
    margin-top: 1rem;
    text-align: center;
    margin-bottom: .5rem;
  }
`;
