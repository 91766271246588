import {useEffect, useState} from "react"

export function AdresseTwoStep(props) {
    const [adresse, setAdresse] =useState(Object.keys(props.adresseNew).length === 0?{}:JSON.parse(props.adresseNew));
     // useState(JSON.parse(props.adresseNew));
    //JSON.parse(props?.adresseNew)
    const handleInputChange = (name, value) => {
        const adresseCopy = {...adresse}       
        adresseCopy[name] = value;
        setAdresse(adresseCopy);
    }
    const handleStepSubmit = e => {
        e.preventDefault();

        const newAdresseCopy={...adresse};

        setAdresse(newAdresseCopy);
        props.handleNextClick(e, 'adresse', JSON.stringify(newAdresseCopy));
    }

    useEffect(() => {
        if (props.adresseNew!=='{}') {
            setAdresse(JSON.parse(props.adresseNew))
        }
    }, [props.adresseNew]);

    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
             <div className="intro-y col-span-12 leading-none">
                <strong >
                    Donnez plus de précision sur l’adresse de votre société
                </strong>
               
            </div>
           
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro d'îlot</div>  
                <input type="text" value={adresse.ilot} className="input w-full border flex-1"
                placeholder="Numéro ilot" onChange={e => 
                handleInputChange('ilot', e.target.value)}/>
            </div>
            
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de lot</div>
                <input type="text" value={adresse.lot} className="input w-full border flex-1"
                placeholder="Numéro lot" onChange={e => 
                handleInputChange('lot', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de parcelle</div>
                <input type="text" value={adresse.parcelle} className="input w-full border flex-1"
                placeholder="Numéro parcelle" onChange={e => 
                handleInputChange('parcelle', e.target.value)}/>
            </div>
            
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de section</div>
                <input type="text" value={adresse.section}  className="input w-full border flex-1"
                placeholder="Numéro section" onChange={e => 
                handleInputChange('section', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}