import { Components } from '..'

export function PaiementForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='moyen_paiement_id'>Moyen_paiement_id</label>
                        <select className='select2 form-control' id='moyen_paiement_id' name='moyen_paiement_id' value={props.usePaiement.moyen_paiement_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setMoyen_paiement_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='montant'>Montant</label>
                        <input className='form-control' type='number' id='montant' name='montant' 
                        placeholder='Montant' value={props.usePaiement.montant ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setMontant(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='type_paiement_id'>Type_paiement_id</label>
                        <select className='select2 form-control' id='type_paiement_id' name='type_paiement_id' value={props.usePaiement.type_paiement_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setType_paiement_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='demande_id'>Demande_id</label>
                        <select className='select2 form-control' id='demande_id' name='demande_id' value={props.usePaiement.demande_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setDemande_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='status'>Status</label>
                        <input className='form-control' type='text' id='status' name='status' 
                        placeholder='Status' value={props.usePaiement.status ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setStatus(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='code_promo_id'>Code_promo_id</label>
                        <select className='select2 form-control' id='code_promo_id' name='code_promo_id' value={props.usePaiement.code_promo_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setCode_promo_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='recu_paiement_url'>Recu_paiement_url</label>
                        <input className='form-control' type='text' id='recu_paiement_url' name='recu_paiement_url' 
                        placeholder='Recu_paiement_url' value={props.usePaiement.recu_paiement_url ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.usePaiement.setRecu_paiement_url(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='col-12 text-right'>
                    <button disabled={props.isDisabled ?? false} type='submit' className='btn btn-primary' 
                    onClick={props.handleFormSubmit}>
                        <span>Enregistrer</span>
                    </button>
                </div>
            </div>
        </form>
    )
}