import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getLocalState, setLocalState } from "../../utils/localStorage";
import ApiService from "../../api/services/api.service";
import { useNavigate } from "react-router-dom";  // Importation du hook useNavigate

const API_URL = "https://devapi.legafrik.com/api/admin/profile";
const apiUrl = new ApiService();

function Profile() {
    const navigate = useNavigate();  // Initialisation de useNavigate
    const currentUser = getLocalState('_currentUser');

    const [profile, setProfile] = useState({
        email: currentUser?.email || "",
        numero_telephone: currentUser?.numero_telephone || "",
        username: currentUser?.username || "",
    });

    const token = JSON.parse(localStorage.getItem("token"));

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setProfile({ ...profile, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(API_URL, profile, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.success) {
                // Met à jour les informations locales de l'utilisateur
                const updatedUser = {
                    ...currentUser,
                    ...profile,  // Utiliser les nouvelles données
                };
                setLocalState('_currentUser', updatedUser);  // Mise à jour du localStorage

                toast.success("Profil mis à jour avec succès !");

                // Redirection vers la page d'accueil après un succès
                navigate("/");
            } else {
                toast.error("Erreur lors de la mise à jour du profil.");
            }
        } catch (error) {
            toast.error("Erreur de communication avec le serveur.");
            console.error("Error updating profile", error);
        }
    };

    return (
        <div className="bg-white text-gray-600 bold mx-5 p-3 box">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

            <h2 className="text-xl font-bold mb-4">Mon Profil</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label>Nom d'utilisateur :</label>
                    <input
                        type="text"
                        name="username"
                        value={profile.username}
                        onChange={handleFormChange}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label>Email :</label>
                    <input
                        type="email"
                        name="email"
                        value={profile.email}
                        className="w-full p-2 border border-gray-300 rounded bg-gray-100"
                        readOnly
                    />
                </div>
                <div className="mb-4">
                    <label>Numéro de téléphone :</label>
                    <input
                        type="text"
                        name="numero_telephone"
                        value={profile.numero_telephone}
                        onChange={handleFormChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <button type="submit" className="w-full bg-theme-1 text-white p-2 rounded">
                    Mettre à jour
                </button>
            </form>
        </div>
    );
}

export default Profile;
