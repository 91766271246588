import { useState } from "react";

export function AdresseTwoStepStatutaire(props) {
  const [adresse, setAddresse] = useState({});

  const adresse1 = props?.adresse1 ? JSON.parse(props?.adresse1) : "{}";

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    const newAdresseCopy = { ...adresse };

    newAdresseCopy["ville"] = adresse1["ville"];
    newAdresseCopy["quartier"] = adresse1["quartier"];
    newAdresseCopy["commune"] = adresse1["commune"];
    newAdresseCopy["code-postale"] = adresse1["code-postale"];
    newAdresseCopy["nom-prenoms-proprietaire"] =
      adresse1["nom-prenoms-proprietaire"];
    newAdresseCopy["numero-telephone"] = adresse1["numero-telephone"];

    setAddresse(newAdresseCopy);

    props.handleNextClick(e, "adresse", JSON.stringify(newAdresseCopy));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12 leading-none">
        <strong>Donnez plus de précision sur l’adresse de votre société</strong>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro d'îlot</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Numéro ilot"
          onChange={(e) => handleInputChange("ilot", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de lot</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Numéro lot"
          onChange={(e) => handleInputChange("lot", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de parcelle</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Numéro parcelle"
          onChange={(e) => handleInputChange("parcelle", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de section</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Numéro section"
          onChange={(e) => handleInputChange("section", e.target.value)}
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
