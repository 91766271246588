import { Components } from '..'

export function DossierForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='libelle'>Libelle</label>
                        <input className='form-control' type='text' id='libelle' name='libelle' 
                        placeholder='Libelle' value={props.useDossier.libelle ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDossier.setLibelle(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='tarif'>Tarif</label>
                        <input className='form-control' type='number' id='tarif' name='tarif' 
                        placeholder='Tarif' value={props.useDossier.tarif ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDossier.setTarif(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='col-12 text-right'>
                    <button disabled={props.isDisabled ?? false} type='submit' className='btn btn-primary' 
                    onClick={props.handleFormSubmit}>
                        <span>Enregistrer</span>
                    </button>
                </div>
            </div>
        </form>
    )
}