import Moment from "moment";
import { Utils } from "../../../utils";

export function RecapStep(props) {
  const {parseJson, isJsonParseable} = Utils.String
 const demande = JSON.parse(
    props?.dataGlobal?.useDemande?.champs_demande
  );

const sigle=props?.dataGlobal?.champs_questionnaireObject["sigle"] ? props?.dataGlobal?.champs_questionnaireObject["sigle"]: null;


const activites=props?.dataGlobal?.champs_questionnaireObject["activites"] ? props?.dataGlobal?.champs_questionnaireObject["activites"]: null;



  const associes = props?.dataGlobal?.champs_questionnaireObject?.associes
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.associes)
    : [];

  const president = props?.dataGlobal?.champs_questionnaireObject?.president
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.president)
    : null;

  const directeur = props?.dataGlobal?.champs_questionnaireObject?.directeur
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.directeur)
    : null;

  const adresse = props?.dataGlobal?.champs_questionnaireObject?.adresse
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse)
    : null;

  // const adresse2 = props?.dataGlobal?.champs_questionnaireObject?.adresse2
  // ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse2)
  // : "{}";

  const gerants = props?.dataGlobal?.champs_questionnaireObject?.gerants
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.gerants)
    : [];

  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e, "", "");
  };

  
  

  const renderArray=(array)=>{
    if(props?.dataGlobal!=null){
        let itemData=array
        return itemData
    }
}

   const renderData = (items) => {
  if (
    props?.dataGlobal?.champs_questionnaireObject != null &&
    items != null
  ) {
    let itemData = items;
    
    const rendDatas = Object.keys(itemData).map((item_field, index) => {
      

      let attribute = `${
        item_field[0].toUpperCase() + String(item_field).substring(1)
      }`;
      attribute = attribute.replace(/_/g, " ");
      attribute = attribute.replace(/-/g, " ");
      if (
        attribute !== "Type" &&
        attribute !== "Fait partie des associes" &&
        itemData[item_field] != null
      ) {
         if (attribute === "Date naissance") {
          return (
            <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text">Date de naissance:</span>
              <span className="flex-item-description">
                {Moment(itemData[item_field].toString().replace(/_/g, " ")).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
          );
        }
        else if (attribute === "Apport") {
          return (
            <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text">Apport:</span>
              <span className="flex-item-description">
              {(typeof itemData[item_field] !== "object" ) ? 
                itemData[item_field].toString().replace(/_/g, " ") : itemData[item_field].toString()}FCFA
              </span>
            </div>
          );
        }
        else if (attribute === "Capital sociale") {
          return (
            <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text">Capital social:</span>
              <span className="flex-item-description">
              {(typeof itemData[item_field] !== "object" ) ? 
                itemData[item_field].toString().replace(/_/g, " ") : itemData[item_field].toString()}FCFA
              </span>
            </div>
          );
        } else {
          return (
           <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text"> {(item_field==="denomination")?"Nom de l'entreprise":
(item_field==="secteur-activite")?"Secteur d'activité":
(item_field==="nationalite")?"Nationalité":
(item_field==="denomination")?"Nom entreprise 1":
(item_field==="denomination")?"Nom entreprise 2":
(item_field==="denomination")?"Nom entreprise 3":
(item_field==="date-naissance")?"Date de naissance":(item_field==="date-reception-certificat")?"Date de réception du registre de commerce":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="date-reception-certificat")?"Date de réception du certificat":
(item_field==="gestion-facture-normalisees")?"Gestion de factures normalisées":
(item_field==="gestion-dfe")?"Gestion de DFE":
(item_field==="prenoms")?"Prénom(s)":
(item_field==="type-nationalite")?"Type de nationalité":
(item_field==="type-mandat")?"Type de mandat":
(item_field==="ville-naissance")?"Ville de naissance":
(item_field==="ville_habitation")?"Ville d'habitation":

(item_field==="prenoms")?"Prénom(s)":
(item_field==="nom-pere")?"Nom du père":
  (item_field==="nom-mere")?"Nom de la mère":
(item_field==="nombre-associes")?"Nombre d'associés":
(item_field==="entreprise-anterieur")?"Avez-vous une entreprise":
(item_field==="date-reception-registre-de-commerce")?"Date de réception du registre de commerce":
(item_field==="localisation-siege")?"Siège de l'entreprise":
(item_field==="capital-social")?"Capital social":
(item_field==="numero-telephone")?"Numéro de boîte postale":
(item_field==="nom-prenoms-proprietaire")?"Nom et prénom(s) du propriétaire":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="numero-telephone")?"Numéro de lot":
(item_field==="numero-telephone")?"Numéro d'ilot":
(item_field==="numero-telephone")?"Numéro de section":
(item_field==="numero-telephone")?"Numéro de parcelle":
(item_field==="numero-telephone")?"Numéro de la carte d’identité":
(item_field==="date-naissance")?"Date de naissance":
(item_field==="lieu-naissance")?"Lieu de naissance":
(item_field==="pays-naissance")?"Pays de naissance":
(item_field==="situation-matrimoniale")?"Situation matrimoniale":
(item_field==="regime-matrimonial")?"Régime matrimonial":
(item_field==="denomination")?"Nom de la société":
(item_field==="forme-sociale")?"Forme sociale":
(item_field==="capital-social")?"Capital social":
(item_field==="siege-sociale")?"Siège social":
(item_field==="numero-telephone")?"Numéro RCCM":
(item_field==="ville-immatriculation")?"Ville d'immatriculation":
(item_field==="nom-representant")?"Nom du représentant":
(item_field==="prenoms")?"Prénom(s) du représentant":
(item_field==="associe-gerant")?"Associé gérant":
(item_field==="fait-partie-des-associes")?"Fait partie des associés":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="date-naissance")?"Date de naissance":
(item_field==="lieu-naissance")?"Lieu de naissance":
(item_field==="pays-naissance")?"Pays de naissance":
(item_field==="situation-matrimoniale")?"Situation matrimoniale":
(item_field==="regime-matrimonial")?"Régime matrimonial":
(item_field==="denomination")?"Nom de la société":
(item_field==="forme-sociale")?"Forme sociale":
(item_field==="capital-social")?"Capital social":
(item_field==="siege-sociale")?"Siège social":
(item_field==="numero-telephone")?"Numéro RCCM":
(item_field==="ville-immatriculation")?"Ville d'immatriculation":
(item_field==="nom-representant")?"Nom du représentant":
(item_field==="prenoms")?"Prénom(s) du représentant":
(item_field==="associe-gerant")?"Associé gérant":
(item_field==="fait-partie-des-associes")?"Fait partie des associés":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="date-naissance")?"Date de naissance":
(item_field==="lieu-naissance")?"Lieu de naissance":
(item_field==="pays-naissance")?"Pays de naissance":
(item_field==="situation-matrimoniale")?"Situation matrimoniale":
(item_field==="regime-matrimonial")?"Régime matrimonial":
(item_field==="denomination")?"Nom de la société":
(item_field==="denomination")?"Nom de la société":
  (item_field==="forme-sociale")?"Forme sociale":
  (item_field==="capital-social")?"Capital social":
  (item_field==="siege-sociale")?"Siège social":
  (item_field==="numero-telephone")?"Numéro RCCM":
  (item_field==="ville-immatriculation")?"Ville d'immatriculation":
  (item_field==="nom-representant")?"Nom du représentant":
  (item_field==="prenoms")?"Prénom(s) du représentant":
  (item_field==="associe-gerant")?"Associé gérant":
  (item_field==="fait-partie-des-associes")?"Associé président":
  (item_field==="fait-partie-des-associes")?"Fait partie des associés":
  (item_field==="numero-telephone")?"Numéro de téléphone":
  (item_field==="date-naissance")?"Date de naissance":attribute
                }:</span>
              <span className="flex-item-description">
                {(typeof itemData[item_field] !== "object" ) ? 
                itemData[item_field].toString().replace(/_/g, " ") : itemData[item_field].toString()}
              </span>
            </div>
          );
        }
      }
    });
    return rendDatas;
  }
};


               return (
                <div
                     className={`grid grid-cols-1 col-span-12 ${
                       props.step !== props.index ? "hidden" : ""
                     } `}
                   >
                <>
                    <div className="flex-content-column">
                        <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>DemandeXXX</h3>
                            </div>
                            <div className="flex-content-item-body">
                            {renderData(demande)}

                            </div>
                        </div>

                        

                        {props?.dataGlobal?.champs_questionnaireObject && sigle!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Sigle</h3>
                            </div>
                            <div className="flex-content-item-body">
                            <div className="flex-item" >
                  <span className="flex-item-description">
                    {isJsonParseable(sigle) ? parseJson(sigle)?.sigle : sigle}
                  </span>
                </div>                            </div>
                        </div>):null}
      
                        {props?.dataGlobal?.champs_questionnaireObject && president!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Président</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(president)}
                            </div>
                        </div>):null}
        
                        {props?.dataGlobal?.champs_questionnaireObject && directeur!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Directeur</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(directeur)}
                            </div>
                        </div>):null}
        ​
        ​
                        {props?.dataGlobal?.champs_questionnaireObject && associes.length>0 ?( <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Associés</h3>
                            </div>
        ​
                            {renderArray(associes).map((data,index)=>{
                                return (<div key={index} className="flex-content-item-body-fex">
                                    <div className="flex-content-item-body-wrapper">
                                        <div className="item-body-wrapper-header">
                                            <h6 className={'item-body-wrapper-header-title'}>Associé {index+1}:</h6>
                                        </div>
                                        <div className="flex-content-item-body-wrapper-mapper">
                                            {renderData(data)}
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>):null}
        ​
                        {props?.dataGlobal?.champs_questionnaireObject && gerants.length>0?( <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Gérants</h3>
                            </div>
        ​
                            {renderArray(gerants).map((data,index)=>{
                                return (<div key={index} className="flex-content-item-body-fex">
                                    <div className="flex-content-item-body-wrapper">
                                        <div className="item-body-wrapper-header">
                                            <h6 className={'item-body-wrapper-header-title'}>Gérant {index+1}:</h6>
                                        </div>
                                        <div className="flex-content-item-body-wrapper-mapper">
                                            {renderData(data)}
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>):null}

                        {props?.dataGlobal?.champs_questionnaireObject && activites!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Activité(s)</h3>
                            </div>
                            <div className="flex-content-item-body">
                            <div className="flex-item" >
                  <span className="flex-item-description">
                    {activites}
                  </span>
                </div>                            </div>
                        </div>):null}
        ​
                        
        ​
                        {props?.dataGlobal?.champs_questionnaireObject && adresse!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Adresse</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(adresse)}
                            </div>
                        </div>):(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Adresse</h3>
                            </div>
                            <div className="flex-content-item-body">
                            <div className="flex-item" >
                              <span className="flex-item-description">
                              Domicilié à Legafrik
                              </span>
                            </div>
                            </div>
                        </div>)}
                    </div>
                    <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
                    
                </>
                </div>
            );
  
}
