import React, { useState, Fragment } from 'react';
import InputWrapper, { VerifiedLabel, VerifictionButton } from './Input.style';


const Input = ({
  type,
  value,
  label,
  name,
  style,
  disabled,
  className,
  inputClassName,
  onUpdate,
  placeholder,
  intlPlaceholderId,
  intlInputLabelId,
  onBlur,
  onFocus,
  verification, // this prop only for number field
  verified, // this prop only for number field
  handleVerification, // this prop only for number field
  secondaryComponent,
  searchIcon,
  iconPosition,
  containerStyle,
  ...rest
}) => {
  // Input State
  const [state, setState] = useState({
    value: value,
  });

  // Add all classs to an array
  const addAllClasses = ['field-wrapper'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Add disabled class
  if (disabled) {
    addAllClasses.push('disabled');
  }

  // Add with search icon class
  if (searchIcon) {
    addAllClasses.push('with-search-icon');
  }

  if (verification) {
    verified
      ? addAllClasses.push('number-verified')
      : addAllClasses.push('send-verification');
  }

  // Input onChange handler
  const handleOnChange = (e) => {
    let currentValue = e.target.value;
    setState({
      ...state,
      value: currentValue,
    });
    onUpdate(currentValue);
  };

  // Init variable for Label For and Input element
  let htmlFor, inputElement;

  // Make Label value to htmlFor
  if (label) {
    htmlFor = label.replace(/\s+/g, '_').toLowerCase();
  }

  // set icon position
  const position = iconPosition || 'left';

  const SearchIcon = searchIcon && (
    <span className={`${position === 'right' ? 'right' : 'left'} search-icon`}>
      {searchIcon}
    </span>
  );

  const withSearchIconClass =
    searchIcon && position === 'right'
      ? 'icon-right'
      : position === 'left'
      ? 'icon-left'
      : '';

  // Label Field
  const LabelField = label && (
    <label htmlFor={htmlFor}>
      {label}
    </label>
  );

  // Set Input element based on type prop
  switch (type) {
    case 'textarea':
      inputElement = (
        <div className="inner-wrap">
          <textarea
              id={htmlFor}
              name={name}
              disabled={disabled}
              value={value}
              onChange={handleOnChange}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              className = {inputClassName}
              style={style}
              {...rest}
          />
        </div>
      );
      break;

    case 'number':
      inputElement = (
        <div className="inner-wrap">
          <input
              type="number"
              id={htmlFor}
              name={name}
              disabled={disabled}
              value={value}
              onChange={handleOnChange}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              className = {inputClassName}
              style={style}
              {...rest}
          />
          {verification && (
            <Fragment>
              {verified ? (
                <VerifiedLabel>
                  {'Verified'}
                </VerifiedLabel>
              ) : (
                <VerifictionButton onClick={handleVerification}>
                  {'Send Verification'}
                </VerifictionButton>
              )}
            </Fragment>
          )}
        </div>
      );
      break;

    case 'url':
      inputElement = (
          <div className="inner-wrap">
            <input
                type="url"
                id={htmlFor}
                name={name}
                disabled={disabled}
                value={value}
                onChange={handleOnChange}
                placeholder={placeholder}
                onBlur={onBlur}
                onFocus={onFocus}
                className = {inputClassName}
                style={style}
                {...rest}
            />
            {verification && (
                <Fragment>
                  {verified ? (
                      <VerifiedLabel>
                        {'Verified'}
                      </VerifiedLabel>
                  ) : (
                      <VerifictionButton onClick={handleVerification}>
                        {'Send Verification'}
                      </VerifictionButton>
                  )}
                </Fragment>
            )}
          </div>
      );
      break;
    case 'password':
      inputElement = (
        <div className="inner-wrap">
          <input
              type="password"
              id={htmlFor}
              name={name}
              disabled={disabled}
              value={value}
              onChange={handleOnChange}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              className = {inputClassName}
              style={style}
              {...rest}
          />
        </div>
      );
      break;

    case 'email':
      inputElement = (
        <div className="inner-wrap">
          <input
              type="email"
              id={htmlFor}
              name={name}
              disabled={disabled}
              value={value}
              onChange={handleOnChange}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              className = {inputClassName}
              style={style}
              {...rest}
          />
        </div>
      );
      break;

    case 'date':
      inputElement = (
          <div className="inner-wrap">
            <input
                type="date"
                id={htmlFor}
                name={name}
                disabled={disabled}
                value={value}
                onChange={handleOnChange}
                placeholder={placeholder}
                onBlur={onBlur}
                onFocus={onFocus}
                className = {inputClassName}
                style={style}
                {...rest}
            />
          </div>
      );
      break;

    case 'time':
      inputElement = (
          <div className="inner-wrap">
            <input
                type="time"
                id={htmlFor}
                name={name}
                disabled={disabled}
                value={value}
                onChange={handleOnChange}
                placeholder={placeholder}
                onBlur={onBlur}
                onFocus={onFocus}
                className = {inputClassName}
                style={style}
                {...rest}
            />
          </div>
      );
      break;

    default:
      inputElement = (
        <div className="inner-wrap">
          {position === 'left' && SearchIcon}
          <input
              type="text"
              id={htmlFor}
              name={name}
              value={value}
              onChange={handleOnChange}
              placeholder={placeholder}
              onBlur={onBlur}
              style={style}
              className={inputClassName}
              {...rest}
          />
          {position === 'right' && SearchIcon}
        </div>
      );
      break;
  }

  return (
    <InputWrapper className={addAllClasses.join(' ')} style={containerStyle}>
      {LabelField || secondaryComponent ? (
        <div>
          {LabelField} {secondaryComponent}
        </div>
      ) : (
        ''
      )}
      {inputElement}
    </InputWrapper>
  );
};

export default Input;
